import { NEW_USER, VERIFY_USER, LOG_OUT, CHANGE_PASSWORD, CLEAR_MESSAGES } from './types';
import { PROXY_URL, API_PATH } from '../config';
import { GOAL_GETEM_TOKEN } from '../token';
import { handleResponse } from './helpers/handleResponse';
import { handleError } from './helpers/handleError';
import { createVerificationHeader, createAuthHeader } from './helpers/createHeader';

export function verifyUser(user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/VerifyUser', {
            method: 'POST',
            headers: createVerificationHeader(user),
            body: JSON.stringify(user)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: VERIFY_USER,
                payload: json
            });
        })
        .catch((error) => {
            handleError(error, dispatch, VERIFY_USER);
        });
    }
}

export function changePassword(user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/ChangePassword', {
            method: 'POST',
            headers: createVerificationHeader(user),
            body: JSON.stringify(user)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: CHANGE_PASSWORD,
                payload: json
            });
        })
        .catch((error) => {
            handleError(error, dispatch, CHANGE_PASSWORD);
        });
    }
}

export function newUser(user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/AddUser', {
            method: 'POST',
            headers: createVerificationHeader(user),
            body: JSON.stringify(user)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: NEW_USER,
                payload: json
            });
        }).catch((error) => handleError(error, dispatch, NEW_USER));
    }
}

export function logOut() {
    return function(dispatch) {
        dispatch({
            type: LOG_OUT,
            payload: {
                success: true
            }
        });
    }
}

export function subscribe(user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/Subscribe', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(user)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: CHANGE_PASSWORD,
                payload: json
            })
        }).catch((error) => handleError(error, dispatch, CHANGE_PASSWORD));
    }
}

export function clearMessages() {
    return function(dispatch) {
        dispatch({
            type: CLEAR_MESSAGES,
            payload: {
                success: true
            }
        })
    }
}