import React, { Component } from 'react';
import { connect } from 'react-redux';

class Unknown extends Component {
    componentDidMount() {
        // Scroll to top of the page
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <div className="White ContentArea">
                <div className="ContentPadder">
                    <h1>Whoops...</h1>
                    <p>Where are you?</p>                                            
                </div>
            </div>
        )        
    }
}

export default connect(null)(Unknown);