import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';
import '../../css/Hzontal.css';

import { setToken } from '../../actions/tokenActions';
import { fetchTokenRewards } from '../../actions/rewardActions';

import { TOKEN_DETAILS_PAGE } from '../../pages';

class Tokens extends Component {
    constructor(props) {
        super(props);

        this.onClickToken = this.onClickToken.bind(this);

        // Create a refrence to the div component that needs to be scrolled horizontally
        this.horizontalRef = React.createRef();
        this.onClickScroll = this.onClickScroll.bind(this);
    }

    onClickToken(token) {
        this.props.setToken(token);
        this.props.fetchTokenRewards(token);

        this.props.history.push('/' +  TOKEN_DETAILS_PAGE);
    }

    onClickScroll(amount) {
        this.horizontalRef.current.scrollLeft += amount;
    }

    render() {
        let user = this.props.user;
        
        let displayError = {"display": "none"};
        if(this.props.error !== null){
            displayError = {"display": "inline-block"};
        }

        // Scroll button stuff
        let sizeOfHzontalItem = 720;
        let leftArrow = "<";
        let rightArrow = ">";
        let displayScrollButtons = {"display": "inline-block"};
        if(this.props.tokenList.length <= 1) {
            displayScrollButtons = {"display": "none"};
        }

        let tokenItems = this.props.tokenList.map(token => {
            let displayFrom = {"display": "block"};
            let displayHolding = {"display": "block"};

            //If this token belongs to current user
            if(token.ownerId === user.id){
                displayHolding = {"display": "none"};
            }
            else{
                displayFrom = {"display": "none"};
            }

            return(
                <div className="HorizontalListItem Pointer" key={token.id} onClick={() => this.onClickToken(token)}>
                    <div className="HzontalLabelArea HzontalTopItem Pointer" style={displayFrom}>
                        <label className="Pointer">From User</label>
                    </div>
                    <div className="HzontalUserArea Pointer" style={displayFrom}>
                        <label className="Pointer">{token.goalUsername}</label>
                    </div>
                    <div className="HzontalLabelArea HzontalTopItem Pointer" style={displayHolding}>
                        <label className="Pointer">Held By</label>
                    </div>
                    <div className="HzontalUserArea Pointer" style={displayHolding}>
                        <label className="Pointer">{token.ownerUsername}</label>
                    </div>
                    <div className="HzontalLabelArea Pointer">
                        <label className="Pointer">Tier</label>
                    </div>
                    <div className="HzontalTierArea Pointer">
                        <label className="Pointer">{token.tierName.charAt(0).toUpperCase() + token.tierName.slice(1)}</label>
                    </div>
                    <div className="HzontalLabelArea Pointer">
                        <label className="Pointer">Quantity</label>
                    </div>
                    <div className="HzontalQuantityArea Pointer">
                        <label className="Pointer">{token.count}</label>
                    </div>
                </div>
            );
        });

        return (
            <div className="HzontalPanelArea">
                <div className="HzontalScrollArea">
                    <button className="Pointer HzontalScrollButton" style={displayScrollButtons} onClick={() => this.onClickScroll(-sizeOfHzontalItem)}>{leftArrow}</button>
                </div>
                <div className="HorizontalList" ref={this.horizontalRef}>                    
                    <div className="HorizontalListItem ErrorMessage HzontalTopItem" style={displayError}>
                        <label>{this.props.error}</label>
                    </div>
                    {tokenItems}
                </div>
                <div className="HzontalScrollArea" style={displayScrollButtons}>
                    <button className="Pointer HzontalScrollButton" onClick={() => this.onClickScroll(sizeOfHzontalItem)}>{rightArrow}</button>
                </div>
            </div>
        )
    }
}

Tokens.propTypes = {
    fetchTokenRewards: PropTypes.func.isRequired,
    setToken: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    error: PropTypes.string
};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, { setToken, fetchTokenRewards })(Tokens);