import { FETCH_REDEMPTION_REQUESTS, RESOLVE_REDEMPTION,
    FETCH_SENT_REDEMPTIONS, CLEAR_MESSAGES } from '../actions/types';

const initialState = {
    receivedList: [],
    sentList: [],
    receivedError: null,
    sentError: null,
    resolveError: null
}

export default function(state = initialState, action) {
    // If this is one of our reponses
    if(action.payload !== undefined){
        // If this is one of our reponses
        if(action.payload.success !== undefined){
            // If the response was a success
            if(action.payload.success === true){
                // Every Dispatch is sent to every Reducer, only act on the Actions that concern this Reducer
                switch(action.type){
                    case FETCH_REDEMPTION_REQUESTS:
                        return {...state, receivedList: JSON.parse(action.payload.payload), receivedError: null};
                    case FETCH_SENT_REDEMPTIONS:
                        return {...state, sentList: JSON.parse(action.payload.payload), sentError: null};
                    // case CLEAR_MESSAGES: Reset all Error/Success messages but retain all other state
                    case CLEAR_MESSAGES:
                        return {...state, receivedError: null, sentError: null, resolveError: null};
                    case RESOLVE_REDEMPTION:
                    default:
                        return state;
                }
            }
            // Else handle the failed response
            else{
                // Every Dispatch is sent to every Reducer, only act on the Actions that concern this Reducer
                switch(action.type){
                    case FETCH_REDEMPTION_REQUESTS:
                        return {...state, receivedList: [], receivedError: action.payload.message};
                    case FETCH_SENT_REDEMPTIONS:
                        return {...state, sentList: [], sentError: action.payload.message};
                    case RESOLVE_REDEMPTION:
                        return {...state, resolveError: action.payload.message};
                    default:
                        return state;
                }
            }
        }             
    }
    // The library first calls this function with a null action to retrieve the initial state
    return initialState;
}