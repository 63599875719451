import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { BROWSE_PAGE, SUB_NONE, SUB_GOALS, SUB_REWARDS } from '../../pages';

class Browseanchor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoverMenu: false,
            hoverSubMenu: false,
            displaySubMenu: {"display": "none"}
        }

        this.onClickGoalList = this.onClickGoalList.bind(this);

        // These events allow the sub-menu to appear below the main navigation element.
        // Sub-menu will stay visible as long as cursor is hovering nav or sub-menu.
        this.onHoverSubMenu = this.onHoverSubMenu.bind(this);
        this.onHoverMenu = this.onHoverMenu.bind(this);
        this.onNoHoverHideMenu = this.onNoHoverHideMenu.bind(this);
        this.onNoHoverHideSubMenu = this.onNoHoverHideSubMenu.bind(this);
    }

    onClickGoalList(newSubPage = SUB_NONE) {
        var thisSubPage = '';
        if(newSubPage !== SUB_NONE) {
            thisSubPage = '/' + newSubPage;
        }
        this.props.history.push( '/' + BROWSE_PAGE + thisSubPage);
    }

    onHoverMenu() {
        this.setState({
            hoverMenu: true,
            displaySubMenu: {"display": "block"}
        });
    }

    onHoverSubMenu() {
        this.setState({
            hoverSubMenu: true,
            displaySubMenu: {"display": "block"}
        });
    }

    onNoHoverHideMenu() {
        // Check the other hover area to see if cursor is there
        if(this.state.hoverSubMenu){
            // Cursor must still be there, so just set this to false
            this.setState({
                hoverMenu: false
            });
        }
        else{
            this.setState({
                hoverMenu: false,
                displaySubMenu: {"display": "none"}
            });
        }
    }

    onNoHoverHideSubMenu() {
        // Check the other hover area to see if cursor is there
        if(this.state.hoverMenu){
            // Cursor must still be there, so just set this to false
            this.setState({
                hoverSubMenu: false
            });
        }
        else{
            this.setState({
                hoverSubMenu: false,
                displaySubMenu: {"display": "none"}
            });
        }
    }

    render() {
        return (
            <div className="NavItem">
                <button className="NavButton Pointer" onClick={() => this.onClickGoalList()} onMouseEnter={this.onHoverMenu} onMouseLeave={this.onNoHoverHideMenu}>Browse</button>
                <div style={this.state.displaySubMenu} className="NavSubMenuArea" onMouseEnter={this.onHoverSubMenu} onMouseLeave={this.onNoHoverHideSubMenu}>
                    <div className="NavSubMenuPadder">
                        <NavLink className="Pointer NavSubMenuItem" to={'/' + BROWSE_PAGE + `/` + SUB_GOALS}>Browse Goals</NavLink><br />
                        <NavLink className="Pointer NavSubMenuItem" to={'/' + BROWSE_PAGE + `/` + SUB_REWARDS}>Browse Rewards</NavLink>
                    </div>
                </div>
            </div>
        )        
    }
}

export default connect(null)(Browseanchor);