import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../css/Loginbar.css';

import { verifyUser } from '../actions/userActions';

class Loginbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();

        const user = {
            username: this.state.username,
            password: this.state.password
        };
        
        this.props.verifyUser(user);

        //Clearing the form fields
        this.setState({
            username: '',
            password: ''
        });
    }

    render() {
        return (
            <div className="Loginbar">
                <div className="LoginFormPadder">
                    <form onSubmit={this.onSubmit}>
                        <label className="LoginBarLabel">Username</label>
                        <input className="LoginBarInput" type="text" name="username" onChange={this.onChange} value={this.state.username} />
                        <label className="LoginBarLabel">Password</label>
                        <input className="LoginBarInput" type="password" name="password" onChange={this.onChange} value={this.state.password} />
                        <button className="LoginBarSubmit Pointer" type="submit">Login</button>
                    </form>
                </div>
            </div>
        ); 
    }
}

Loginbar.propTypes = {
    verifyUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, { verifyUser })(Loginbar);