import { combineReducers } from 'redux';
import goalReducer from './goalReducer';
import userReducer from './userReducer';
import rewardReducer from './rewardReducer';
import tokenReducer from './tokenReducer';
import tierReducer from './tierReducer';
import redemptionReducer from './redemptionReducer';
import completionsReducer from './completionsReducer';
import subscriptionReducer from './subscriptionReducer';

export default combineReducers({
    goals: goalReducer,
    user: userReducer,
    rewards: rewardReducer,
    tokens: tokenReducer,
    tiers: tierReducer,
    redemptions: redemptionReducer,
    completions: completionsReducer,
    subscriptions: subscriptionReducer
})