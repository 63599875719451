import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Goalsform.css';
import '../../css/Layout.css';

import { createGoal } from '../../actions/goalActions';
import Tierselection from '../Tierselection';

class Goalsform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            count: 1,
            unlimited: false
        }

        this.onChange = this.onChange.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        // If the User succceeds in Creating a Goal
        if (this.props.success !== prevProps.success && this.props.success != null) {
            // Clearing the form fields
            this.setState({
                title: '',
                description: '',
                count: 1,
                unlimited: false
            });
        }
        // Else If the User succeeds in Creating a Goal after already succeeding.
        else if (this.props.success != null && this.props.goalList.length !== prevProps.goalList.length) {
            // Clearing the form fields
            this.setState({
                title: '',
                description: '',
                count: 1,
                unlimited: false
            });
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onCheck(e) {
        this.setState({[e.target.name]: e.target.checked});
    }

    onSubmit(e) {
        e.preventDefault();
        let user = this.props.user;
        
        const goal = {
            title: this.state.title,
            description: this.state.description,
            ownerId: user.id,
            tier: this.props.selectedTier,
            count: this.state.count,
            unlimited: this.state.unlimited
        };
        
        this.props.createGoal(goal, user);
    }

    render() {
        let renderError;
        if(this.props.error !== null){
            renderError = <div className="ErrorMessage"><label>{this.props.error}</label></div>;
        }

        let renderSuccess;
        if(this.props.success !== null){
            renderSuccess = <div className="SuccessMessage"><label>{this.props.success}</label></div>;
        }

        let displayLimited;
        if(this.state.unlimited === true){
            displayLimited = {"backgroundColor": "grey"};
        }

        return (
            <div className="GoalForm">
                <form onSubmit={this.onSubmit}>
                    <div className="NewGoalTitleArea">
                        <label className="FormLabel Bold">Title</label>
                        <br/>
                        <input type="text" name="title" className="PurpleBorder NewGoalTitleInput" onChange={this.onChange} value={this.state.title} />
                    </div>
                    <div className="NewGoalCompArea">
                        <label className="FormLabel Bold">Possible Completions</label>
                        <br /><br />
                        <div className="NewGoalColumnContainer">
                            <div className="NewGoalColumn">
                                <label className="FormLabel NewGoalQuantLabel">Quantity</label>
                                <input type="number" name="count" className="NewGoalCounter PurpleBorder" onChange={this.onChange} value={this.state.count} style={displayLimited} />
                            </div>
                            <div className="NewGoalColumn">
                                <input type="checkbox" className="Pointer NewGoalCkBx" name="unlimited" id="unlimitedCheck" onChange={this.onCheck} checked={this.state.unlimited} />
                                <label htmlFor="unlimitedCheck" className="FormLabel Pointer">Unlimited</label>
                                <br />
                            </div>
                        </div>
                    </div>
                    <div className="NewGoalTierArea">
                        <label className="FormLabel Bold">Tier</label>
                        <br />
                        <Tierselection />
                    </div>
                    <div className="NewGoalDescArea">
                        <label className="FormLabel Bold">Description</label>
                        <br />
                        <textarea name="description" className="PurpleBorder NewGoalDescInput" onChange={this.onChange} value={this.state.description} />
                    </div>
                    {renderError}{renderSuccess}
                    <div className="NewGoalSubmitArea">
                        <button className="Pointer NewGoalSubButton" type="submit">Create Goal</button>
                    </div>
                </form>
            </div>
        ); 
    }
}

Goalsform.propTypes = {
    createGoal: PropTypes.func.isRequired,
    user : PropTypes.object.isRequired,
    selectedTier: PropTypes.number.isRequired,
    error: PropTypes.string
};

const mapStateToProps = state => ({
    goalList: state.goals.goalList,
    user: state.user,
    selectedTier: state.tiers.selectedTier,
    error: state.goals.formError,
    success: state.goals.successMessage
});

export default connect(mapStateToProps, { createGoal } )(Goalsform);