import { FETCH_GOALS, NEW_GOAL, BROWSE_GOALS, FETCH_GOAL, 
    COMPLETE_GOAL, DOWN_GOAL } from './types';
import { PROXY_URL, API_PATH } from '../config';
import { handleResponse } from './helpers/handleResponse';
import { handleError } from './helpers/handleError';
import { createAuthHeader } from './helpers/createHeader';

export function fetchGoals(owner) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/GoalList', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(owner)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: FETCH_GOALS,
                payload: json
            })
        }).catch((error) => handleError(error, dispatch, FETCH_GOALS));
    };
}

export function createGoal(goal, owner) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/AddGoal', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(goal)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: NEW_GOAL,
                payload: json
            });
            //Also dispatch the fetchGoals method so our list refreshes
            dispatch(fetchGoals(owner));
        }).catch((error) => handleError(error, dispatch, NEW_GOAL));
    }
}

export function browseGoalsByTitle(goal, user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/BrowseGoalsByTitle', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify({...goal, ...user})
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: BROWSE_GOALS,
                payload: json
            })
        }).catch((error) => handleError(error, dispatch, BROWSE_GOALS));
    }
}

export function browseGoalsByUsername(searchUser, user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/BrowseGoalsByUsername', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify({...searchUser, ...user})
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: BROWSE_GOALS,
                payload: json
            })
        }).catch((error) => handleError(error, dispatch, BROWSE_GOALS));
    }
}

export function goalDetails(goal) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/GoalDetails', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(goal)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: FETCH_GOAL,
                payload: json
            })
        }).catch((error) => handleError(error, dispatch, FETCH_GOAL));
    }
}

export function completeGoal(goal, user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/CompleteGoal', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify({...goal, ...user})
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: COMPLETE_GOAL,
                payload: json
            })
        }).catch((error) => handleError(error, dispatch, COMPLETE_GOAL));
    }
}

export function takeDownGoal(goal, user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/TakeDownGoal', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify({...goal, ...user})
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: DOWN_GOAL,
                payload: json
            })
        }).catch((error) => handleError(error, dispatch, DOWN_GOAL));
    }
}