import { FETCH_SUBS } from './types';
import { PROXY_URL, API_PATH } from '../config';
import { handleResponse } from './helpers/handleResponse';
import { handleError } from './helpers/handleError';
import { createPlainHeader } from './helpers/createHeader';

export function fetchSubscriptionTiers() {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/FetchSubscriptionTiers', {
            method: 'POST',
            headers: createPlainHeader(),
            body: ''
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: FETCH_SUBS,
                payload: json
            });
        }).catch((error) => handleError(error, dispatch, FETCH_SUBS));
    }
}