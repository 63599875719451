import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';
import '../../css/Browsepage.css';

import { SUB_NONE, SUB_GOALS, SUB_REWARDS } from '../../pages.js';
import Goals from '../hzontals/Goals';
import Browsefilterform from '../forms/Browsefilterform';
import { browseGoalsByTitle } from '../../actions/goalActions';
import { browseRewardsByTitle } from '../../actions/rewardActions';
import Rewards from '../hzontals/Rewards';
import { clearMessages } from '../../actions/userActions';

class Browsepage extends Component {
    constructor(props) {
        super(props);

        this.goalRef = React.createRef();
        this.rewardRef = React.createRef();
        // Prevents render from moving page except by navigation
        this.previousSubPage = '';
    }

    componentWillMount() {
        // Clear any previous Error/Success messages on page load
        this.props.clearMessages();

        let user = {
            userId: this.props.user.id
        };

        let goal = {
            title: ''
        };

        let reward = {
            title: ''
        };

        this.props.browseGoalsByTitle(goal, user);
        this.props.browseRewardsByTitle(reward, user);
    }

    componentDidMount() {
        this.moveWindowPosition();
    }

    moveWindowPosition() {
        // If refs have been placed on page
        if(this.goalRef.current !== null){
            let topOffset = 0;
            switch(this.props.subPage){
                case SUB_GOALS:
                    topOffset = this.goalRef.current.offsetTop;
                    break;
                case SUB_REWARDS:
                    topOffset = this.rewardRef.current.offsetTop;
                    break;
                case SUB_NONE:
                default:
                    break;
            }
            window.scrollTo(0, topOffset);
        }
    }

    render() {
        // Prevents re-render from moving page
        if(this.previousSubPage !== this.props.subPage){
            this.moveWindowPosition();
            this.previousSubPage = this.props.subPage;
        }
        
        return (
            <div>
                <div className="White ContentArea" ref={this.goalRef}>
                    <div className="ContentPadder">
                        <div className="LeftRightSmall">
                            <h1>Browse Goals</h1>
                            <Browsefilterform filterType={'goals'} error={this.props.goalError}/>
                        </div>
                        <div className="LeftRightBig BrowseListArea">
                            <Goals history={this.props.history}/>
                        </div>
                    </div>
                </div>
                <div className="Purple ContentArea" ref={this.rewardRef}>
                    <div className="ContentPadder">
                        <div className="LeftRightBig BrowseListArea">
                           <Rewards history={this.props.history}/>
                        </div>
                        <div className="LeftRightSmall">
                            <h1>Browse Rewards</h1>
                            <Browsefilterform filterType={'rewards'} error={this.props.rewardError}/>
                        </div>
                    </div>  
                </div>
            </div>
        );   
    }
}

Browsepage.propTypes = {
    browseGoalsByTitle: PropTypes.func.isRequired,
    browseRewardsByTitle: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    clearMessages: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    user: state.user,
    goalError: state.goals.browseError,
    rewardError: state.rewards.browseError
});

export default connect(mapStateToProps, { browseGoalsByTitle, browseRewardsByTitle,
    clearMessages })(Browsepage);