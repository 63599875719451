import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Welcome.css';
import '../../css/Layout.css';

import { CREATE_USER_PAGE } from '../../pages';
import { clearMessages } from '../../actions/userActions';

class Welcome extends Component {
    constructor(props) {
        super(props);

        this.onClickGetStarted = this.onClickGetStarted.bind(this);
    }

    componentWillMount() {
        // Clear any previous Error/Success messages on page load
        this.props.clearMessages();
    }

    componentDidMount() {
        // Scroll to top of the page
        window.scrollTo(0, 0);
    }

    onClickGetStarted() {
        this.props.history.push('/' + CREATE_USER_PAGE);
    }

    render() {
        return (
            <div>
                <div className="White ContentArea">
                    <div className="ContentPadder">
                        <div className="SplashBody">
                            <h1 className="SlimHeader">You can Goal Get 'Em!</h1>
                            <p>
                                Will you seek out awesome Rewards by helping others complete their Goals? 
                                Or maybe create Goals to inspire those around you to achieve greatness?
                            </p>
                            <h2 className="SlimHeader">Under Construction</h2>
                            <p>
                                It won't take you much more browsing to notice that this website is undergoing 
                                construction. However, please check back later because updates are definitely
                                coming.
                            </p>
                            <p>Still hanging around? Thanks! Here's some sweet lorem ipsum, just for you. 
                                Nam vehicula eget felis vel aliquam. Phasellus eget rutrum neque. Ut quis ante 
                                pharetra sem rutrum scelerisque et a augue. Fusce nec tristique augue. Aenean 
                                sodales, eros porta sollicitudin facilisis, mauris felis cursus sapien, non 
                                fermentum metus sapien rhoncus nisl. Aenean fermentum lectus leo, sed tempor mi 
                                malesuada sit amet. Suspendisse egestas velit et lacus tincidunt convallis.
                            </p>
                            <p>Quisque volutpat cursus est sed aliquam. Sed egestas a nisl a posuere. Maecenas 
                                efficitur suscipit erat, eget tristique nisi mollis vitae. Phasellus sed ultrices 
                                arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In hac habitasse 
                                platea dictumst.
                            </p>
                        </div>
                        <div className="SplashGraphics">
                            <div className="FakePicture">Image coming soon</div>
                        </div>                       
                    </div>
                </div>
                <div className="Purple ContentArea">
                    <div className="ContentPadder">
                        <div className="HowBubble HowTitle">
                            <h1>How It Works</h1>
                        </div>                        
                    </div>
                </div>
                <div className="White ContentArea">
                    <div className="ContentPadder">
                        <div className="LeftRight">
                            <div className="LeftRightPadder">
                                <h3 className="Centered">Creator</h3>
                                <p>
                                    Fusce dignissim tincidunt molestie. Praesent vitae nisl tellus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Maecenas cursus posuere sem, id efficitur nulla venenatis vel. Vestibulum aliquet dui in porta faucibus. Proin volutpat auctor mauris, et sodales odio euismod ac. Sed congue mi ipsum, sed tristique justo malesuada a. Cras in justo ut dolor convallis suscipit et vestibulum mi.
                                </p>
                                <p>
                                    Proin neque mauris, faucibus et nisi eget, facilisis varius enim. Maecenas at elementum dolor. Etiam placerat maximus leo, vitae mattis enim convallis at. Nullam eleifend ligula urna, luctus pharetra est fermentum sit amet. Suspendisse urna urna, semper eget faucibus id, suscipit varius velit. Aenean nec lorem et est tempus tincidunt vel non odio. Ut gravida condimentum justo eu sagittis. Donec vehicula suscipit libero, non ultrices nisi. Sed tincidunt turpis at tortor euismod maximus.
                                </p>
                            </div>
                        </div>
                        <div className="VerticalDivider"></div>
                        <div className="LeftRight">
                            <div className="LeftRightPadder">
                                <h3 className="Centered">Completer</h3>
                                <p>
                                    Nullam porttitor finibus sapien non convallis. Sed eget leo at sapien euismod bibendum. Maecenas eleifend massa a urna ultrices aliquam in eget nisi. Aliquam lorem orci, pellentesque et suscipit sit amet, eleifend vel odio. Sed eget metus at velit scelerisque feugiat a at odio. Donec sit amet mattis tellus. Donec in enim erat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Suspendisse sed molestie est, vel rutrum tortor. Pellentesque volutpat quis turpis ac aliquam.
                                </p>
                                <p>
                                    Mauris efficitur finibus feugiat. Phasellus aliquam leo nisl, in pharetra est consectetur ut. Morbi varius purus at pellentesque tincidunt. Suspendisse gravida a magna a venenatis. Duis et mi ex. Ut tristique venenatis orci. Integer eleifend lorem sit amet congue dignissim. Nulla odio massa, suscipit vitae condimentum a, consectetur vel risus. Nam dolor mi, pretium ut porta nec, gravida eget odio.
                                </p>
                            </div>
                        </div>
                        <div className="StartButtonArea">
                            <div className="StartButtonPadder">
                                <br />
                                <label className="StartProvoke">What role will you take on?</label>
                                <br />
                                <button className="GetStartedButton Pointer" onClick={() => this.onClickGetStarted()}>Get Started!</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )        
    }
}

Welcome.propTypes = {
    clearMessages: PropTypes.func.isRequired
}

export default connect(null, { clearMessages })(Welcome);