import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../css/Userbar.css';
import { BROWSE_PAGE, LOGIN_PAGE } from '../pages.js';

import Userbadge from './Userbadge';
import Usernav from './nav/Usernav';
import Loginbar from './Loginbar';

class Userbar extends Component {
    componentDidUpdate(prevProps) {
        // If the user is logging in, redirect to the Browse page
        if (this.props.user.id !== prevProps.user.id && this.props.user.id != null) {
            this.props.history.push('/' + BROWSE_PAGE);
        }
        // Else If the user is attempting to login from the Loginbar and fails,
        // redirect to the Log In page
        // Conditionally also checks that the user is not already on the Log In page
        // to prevent multiple failed attempts from flooding the browser history
        else if(this.props.user.verifyError !== null && prevProps.user.verifyError === null
            && this.props.history.location.pathname !== '/' + LOGIN_PAGE) {
            this.props.history.push('/' + LOGIN_PAGE);
        }
        // Else If the user was logged in but is now logged out,
        // redirect to the Log In page
        else if(this.props.user.id === null && prevProps.user.id !== null) {
            this.props.history.push('/' + LOGIN_PAGE);
        }
    }

    render() {
        let user = this.props.user;
        let renderElement;

        if(user.username === ''){
            renderElement = <Loginbar />
        }
        else{
            renderElement = [
                <Usernav key="1" history={this.props.history}/>,
                <Userbadge key="2"/>
            ]
        }

        return (
            <div className="Userbar">
                {renderElement}
            </div>
        );   
    }
}

Userbar.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(Userbar);