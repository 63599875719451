import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../css/Tierselection.css';

import { fetchTiers, setSelectedTier } from '../actions/tierActions';

class Tierselection extends Component {
    constructor(props) {
        super(props);

        this.onChangeTier = this.onChangeTier.bind(this);
    }

    onChangeTier(event) {
        this.props.setSelectedTier(parseInt(event.target.value));
    }

    componentWillMount() {
        this.props.fetchTiers();
    }

    render() {
        let renderTiers = this.props.tierList.map(tier => (
            <option value={tier.id} key={tier.id}>{tier.name.charAt(0).toUpperCase() + tier.name.slice(1)}</option>
        ));

        return (
            <select value={this.props.selectedTier} onChange={this.onChangeTier} className="PurpleBorder TierSelect">
                {renderTiers}
            </select>
        );
    }
}

Tierselection.propTypes = {
    fetchTiers: PropTypes.func.isRequired,
    setSelectedTier: PropTypes.func.isRequired,
    tierList: PropTypes.array.isRequired,
    selectedTier: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
    tierList: state.tiers.tierList,
    selectedTier: state.tiers.selectedTier
});

export default connect(mapStateToProps, { fetchTiers, setSelectedTier })(Tierselection);