import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';
import '../../css/Goalspage.css';

import { SUB_NONE, SUB_CREATE_GOAL, SUB_COMP_REQ,
    SUB_MY_GOALS, SUB_COMPLETIONS } from '../../pages';
import Goalsform from '../forms/Goalsform';
import Goals from '../hzontals/Goals';
import Completionrequests from '../hzontals/Completionrequests';
import { fetchGoals } from '../../actions/goalActions';
import { fetchCompletionRequests, fetchCompletionAttempts } from '../../actions/completionActions';
import { clearMessages } from '../../actions/userActions';

class Goalspage extends Component {
    constructor(props) {
        super(props);

        this.newRef = React.createRef();
        this.compRef = React.createRef();
        this.goalsRef = React.createRef();
        this.myCompRef = React.createRef();
        // Prevents render from moving page except by navigation
        this.previousSubPage = '';
    }

    componentWillMount() {
        // Clear any previous Error/Success messages on page load
        this.props.clearMessages();

        this.props.fetchGoals(this.props.user);
        this.props.fetchCompletionRequests(this.props.user);
        this.props.fetchCompletionAttempts(this.props.user);
    }

    componentDidMount() {
        this.moveWindowPosition();
    }

    moveWindowPosition() {
        // If refs have been placed on page
        if(this.newRef.current !== null){
            let topOffset = 0;
            switch(this.props.subPage){
                case SUB_CREATE_GOAL:
                    topOffset = this.newRef.current.offsetTop;
                    break;
                case SUB_COMP_REQ:
                    topOffset = this.compRef.current.offsetTop;
                    break;
                case SUB_MY_GOALS:
                    topOffset = this.goalsRef.current.offsetTop;
                    break;
                case SUB_COMPLETIONS:
                        topOffset = this.myCompRef.current.offsetTop;
                        break;
                case SUB_NONE:
                default:
                    break;
            }
            window.scrollTo(0, topOffset);
        }
    }

    render() {
        // Prevents re-render from moving page
        if(this.previousSubPage !== this.props.subPage){
            this.moveWindowPosition();
            this.previousSubPage = this.props.subPage;
        }

        return (
            <div>
                <div className="White ContentArea" ref={this.myCompRef}>
                    <div className="ContentPadder">
                        <div className="LeftRightSmall NewGoalTalkArea">
                            <h1 className="SlimHeader">Completed Goals</h1>
                            <p>
                                Proin neque mauris, faucibus et nisi eget, facilisis varius enim. Maecenas at elementum dolor. Etiam placerat maximus leo, vitae mattis enim convallis at. Nullam eleifend ligula urna, luctus pharetra est fermentum sit amet. Suspendisse urna urna, semper eget faucibus id, suscipit varius velit. Aenean nec lorem et est tempus tincidunt vel non odio. Ut gravida condimentum justo eu sagittis. Donec vehicula suscipit libero, non ultrices nisi. Sed tincidunt turpis at tortor euismod maximus.
                            </p>
                        </div>
                        <div className="LeftRightBig">
                            <Completionrequests completionList={this.props.sentList} error={this.props.sentError}/>
                        </div>
                    </div> 
                </div>
                <div className="Purple ContentArea" ref={this.compRef}>
                    <div className="ContentPadder">
                        <div className="LeftRightBig">
                            <Completionrequests completionList={this.props.receivedList} error={this.props.receivedError}/>
                        </div>
                        <div className="LeftRightSmall CompReqArea">
                            <h1 className="SlimHeader">Completion Requests</h1>
                            <p>
                                Proin neque mauris, faucibus et nisi eget, facilisis varius enim. Maecenas at elementum dolor. Etiam placerat maximus leo, vitae mattis enim convallis at. Nullam eleifend ligula urna, luctus pharetra est fermentum sit amet. Suspendisse urna urna, semper eget faucibus id, suscipit varius velit. Aenean nec lorem et est tempus tincidunt vel non odio. Ut gravida condimentum justo eu sagittis. Donec vehicula suscipit libero, non ultrices nisi. Sed tincidunt turpis at tortor euismod maximus.
                            </p>    
                        </div>
                    </div>
                </div>
                <div className="White ContentArea" ref={this.goalsRef}>
                    <div className="ContentPadder">
                        <div className="LeftRightSmall MyGoalsArea">
                            <h1 className="SlimHeader">My Goals</h1>
                            <p>
                                Proin neque mauris, faucibus et nisi eget, facilisis varius enim. Maecenas at elementum dolor. Etiam placerat maximus leo, vitae mattis enim convallis at. Nullam eleifend ligula urna, luctus pharetra est fermentum sit amet. Suspendisse urna urna, semper eget faucibus id, suscipit varius velit. Aenean nec lorem et est tempus tincidunt vel non odio. Ut gravida condimentum justo eu sagittis. Donec vehicula suscipit libero, non ultrices nisi. Sed tincidunt turpis at tortor euismod maximus.
                            </p>    
                        </div>
                        <div className="LeftRightBig">
                            <Goals history={this.props.history}/>
                        </div>
                    </div>
                </div>
                <div className="Purple ContentArea" ref={this.newRef}>
                    <div className="ContentPadder">
                        <div className="LeftRightBig">
                            <Goalsform />
                        </div>
                        <div className="LeftRightSmall NewGoalTalkArea">
                            <h1 className="SlimHeader">Create New Goal</h1>
                            <p>
                                This is where you create new Goals that other Users will complete. Maecenas at elementum dolor. Etiam placerat maximus leo, vitae mattis enim convallis at. Nullam eleifend ligula urna, luctus pharetra est fermentum sit amet. Suspendisse urna urna, semper eget faucibus id, suscipit varius velit. Aenean nec lorem et est tempus tincidunt vel non odio.
                            </p> 
                        </div>
                    </div>
                </div>
            </div>
        );   
    }
}

Goalspage.propTypes = {
    fetchGoals: PropTypes.func.isRequired,
    fetchCompletionRequests: PropTypes.func.isRequired,
    fetchCompletionAttempts: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    receivedList: PropTypes.array.isRequired,
    sentList: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    user: state.user,
    receivedList: state.completions.receivedList,
    sentList: state.completions.sentList,
    receivedError: state.completions.receivedError,
    sentError: state.completions.sentError
});

export default connect(mapStateToProps, { fetchGoals, fetchCompletionRequests, 
    fetchCompletionAttempts, clearMessages })(Goalspage);