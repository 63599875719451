import { FETCH_OWN_REWARDS, CREATE_REWARD, FETCH_ELIGIBLE_REWARDS, 
    REWARD_DETAILS, FETCH_GOAL_REWARDS, TAKE_DOWN_REWARD, 
    BROWSE_REWARDS, CLEAR_MESSAGES } from '../actions/types';

const initialState = {
    rewardList: [],
    selectedReward: null,
    listError: null,
    selectedError: null,
    formError: null,
    downError: null,
    successMessage: null,
    downSuccessMessage: null
}

export default function(state = initialState, action) {
    // If this is one of our reponses
    if(action.payload !== undefined){
        // If this is one of our reponses
        if(action.payload.success !== undefined){
            // If the response was a success
            if(action.payload.success === true){
                // Every Dispatch is sent to every Reducer, only act on the Actions that concern this Reducer
                switch(action.type){
                    case CREATE_REWARD:
                        return {...state, formError: null, successMessage: action.payload.message};
                    case TAKE_DOWN_REWARD:
                        return {...state, selectedReward: JSON.parse(action.payload.payload), downError: null, downSuccessMessage: action.payload.message};
                    case BROWSE_REWARDS:
                        return {...state, rewardList: JSON.parse(action.payload.payload), browseError: null};
                    case FETCH_OWN_REWARDS:
                    case FETCH_ELIGIBLE_REWARDS:
                    case FETCH_GOAL_REWARDS:
                        return {...state, rewardList: JSON.parse(action.payload.payload), listError: null};
                    case REWARD_DETAILS:
                        return {...state, selectedReward: JSON.parse(action.payload.payload), selectedError: null};
                    // case CLEAR_MESSAGES: Reset all Error/Success messages but retain all other state
                    case CLEAR_MESSAGES:
                        return {...state, listError: null, selectedError: null, formError: null, downError: null,
                            successMessage: null, downSuccessMessage: null};
                    default:
                        return state;
                }
            }
           // Else handle the failed response
            else{
                // Every Dispatch is sent to every Reducer, only act on the Actions that concern this Reducer
                switch(action.type){
                    case FETCH_OWN_REWARDS:
                    case FETCH_ELIGIBLE_REWARDS:
                    case FETCH_GOAL_REWARDS:
                        return {...state, rewardList: [], listError: action.payload.message};
                    case BROWSE_REWARDS:
                        return {...state, rewardList: [], browseError: action.payload.message}
                    case CREATE_REWARD:
                        return {...state, formError: action.payload.message, successMessage: null};
                    case TAKE_DOWN_REWARD:
                        return {...state, downError: action.payload.message, downSuccessMessage: null};
                    case REWARD_DETAILS:
                        return {...state, selectedError: action.payload.message}
                    default:
                        return state;
                }
            }
        }             
    }
    // The library first calls this function with a null action to retrieve the initial state
    return initialState;
}