import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';
import '../../css/Hzontal.css';

import { fetchGoals, goalDetails } from '../../actions/goalActions';
import { fetchGoalRewards } from '../../actions/rewardActions';

import { GOAL_DETAILS_PAGE } from '../../pages';

class Goals extends Component {
    constructor(props) {
        super(props);

        this.onGoalClick = this.onGoalClick.bind(this);

        // Create a refrence to the div component that needs to be scrolled horizontally
        this.horizontalRef = React.createRef();
        this.onClickScroll = this.onClickScroll.bind(this);
    }

    onGoalClick(goal) {
        this.props.goalDetails(goal);

        //Fetch the possible rewards for the goal we are about to view
        this.props.fetchGoalRewards(goal);

        this.props.history.push('/' + GOAL_DETAILS_PAGE);
    }

    onClickScroll(amount) {
        this.horizontalRef.current.scrollLeft += amount;
    }

    render() {
        let displayError = {"display": "none"};
        if(this.props.error !== null){
            displayError = {"display": "inline-block"};
        }

        // Scroll button stuff
        let sizeOfHzontalItem = 720;
        let leftArrow = "<";
        let rightArrow = ">";
        let displayScrollButtons = {"display": "inline-block"};
        if(this.props.goalList.length <= 1) {
            displayScrollButtons = {"display": "none"};
        }

        let goalItems = this.props.goalList.map(goal => {
            let available = 'Unlimited';
            if(goal.limitCount !== null){
                available = goal.limitCount - (goal.count + goal.pendingCount);
            }

            if(goal.complete){
                available = 'None';
            }

            let displayOwner = {"display": "block"};
            if(goal.ownerId === this.props.user.id){
                displayOwner = {"display": "none"};
            }

            return (
                <div className="HorizontalListItem Pointer" key={goal.id} onClick={() => this.onGoalClick(goal)}>                        
                    <div className="HzontalTitleArea HzontalTopItem Pointer">
                        <label className="Pointer">{goal.title}</label>
                    </div>
                    <div className="HzontalMessageArea Pointer">
                        <label className="Pointer">{goal.description}</label>
                    </div>
                    <div className="HzontalLabelArea Pointer">
                        <label className="Pointer">Tier</label>
                    </div>
                    <div className="HzontalTierArea Pointer">
                        <label className="Pointer">{goal.tierName.charAt(0).toUpperCase() + goal.tierName.slice(1)}</label>
                    </div>
                    <div className="HzontalLabelArea Pointer">
                        <label className="Pointer">Completions Available</label>
                    </div>
                    <div className="HzontalQuantityArea Pointer">
                        <label className="Pointer">{available} ({goal.pendingCount} Pending)</label>
                    </div>
                    <div className="HzontalLabelArea Pointer" style={displayOwner}>
                        <label className="Pointer">Created by</label>
                    </div>
                    <div className="HzontalUserArea Pointer" style={displayOwner}>
                        <label className="Pointer">{goal.ownerUsername}</label>
                    </div>
                </div>
            );
        });

        return (
            <div className="HzontalPanelArea">
                <div className="HzontalScrollArea">
                    <button className="Pointer HzontalScrollButton" style={displayScrollButtons} onClick={() => this.onClickScroll(-sizeOfHzontalItem)}>{leftArrow}</button>
                </div>
                <div className="HorizontalList" ref={this.horizontalRef}>                    
                    <div className="HorizontalListItem ErrorMessage HzontalTopItem" style={displayError}>
                        <label>{this.props.error}</label>
                    </div>
                    {goalItems}
                </div>
                <div className="HzontalScrollArea" style={displayScrollButtons}>
                    <button className="Pointer HzontalScrollButton" onClick={() => this.onClickScroll(sizeOfHzontalItem)}>{rightArrow}</button>
                </div>
            </div>
        );
    }
}

Goals.propTypes = {
    fetchGoals: PropTypes.func.isRequired,
    fetchGoalRewards: PropTypes.func.isRequired,
    goalDetails: PropTypes.func.isRequired,
    goalList: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    error: PropTypes.string
};

const mapStateToProps = state => ({
    goalList: state.goals.goalList,
    error: state.goals.listError,
    user: state.user
});

export default connect(mapStateToProps, { fetchGoals, goalDetails, fetchGoalRewards })(Goals);