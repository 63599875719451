import { FETCH_SUBS, CLEAR_MESSAGES } from '../actions/types';

const initialState = { 
    subList: [],
    error: null
}

export default function(state = initialState, action) {
    // If this is one of our reponses
    if(action.payload !== undefined){
        // If this is one of our reponses
        if(action.payload.success !== undefined){
            // If the response was a success
            if(action.payload.success === true){
                // Every Dispatch is sent to every Reducer, only act on the Actions that concern this Reducer
                switch(action.type){
                    case FETCH_SUBS:
                        return {subList: JSON.parse(action.payload.payload)};
                    // case CLEAR_MESSAGES: Reset all Error/Success messages but retain all other state
                    case CLEAR_MESSAGES:
                        return {...state, error: null};
                    default:
                        return state;
                }
            }
            // Else handle the failed response
            else{
                // Every Dispatch is sent to every Reducer, only act on the Actions that concern this Reducer
                switch(action.type){
                    case FETCH_SUBS:
                        return {...state, error: action.payload.message};
                    default:
                        return state;
                }
            }
        }             
    }
    // The library first calls this function with a null action to retrieve the initial state
    return initialState;
}