import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';

import { clearMessages } from '../../actions/userActions';

class Aboutpage extends Component {
    componentWillMount() {
        // Clear any previous Error/Success messages on page load
        this.props.clearMessages();
    }

    componentDidMount() {
        // Scroll to top of the page
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <div>
                <div className="White ContentArea">
                    <div className="ContentPadder">
                        <h1 className="SlimHeader">All About Goal Get 'Em</h1>
                        <p>
                            Curabitur elementum leo vel risus lacinia malesuada. Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent non sollicitudin magna. Curabitur non molestie lacus. Cras blandit vulputate odio, eu commodo libero porta at. Curabitur vitae metus rutrum, eleifend mi vel, semper nibh.
                        </p>
                    </div>
                </div>
                <div className="Purple ContentArea">
                    <div className="ContentPadder">
                        <h1 className="SlimHeader">What's Next?</h1>
                        <p>
                            Aenean a felis purus. In ultrices neque risus, sed feugiat odio auctor ut. Ut quis turpis sit amet velit convallis accumsan. Maecenas gravida tortor sed arcu commodo, ac egestas nisi commodo. Nunc sit amet massa magna. Maecenas nec aliquam elit, at finibus tortor. Suspendisse varius tortor id nibh feugiat dapibus a vel lectus. Mauris congue augue eget facilisis sagittis.
                        </p>                     
                    </div>
                </div>
            </div>
        );
    }
}

Aboutpage.propTypes = {
    clearMessages: PropTypes.func.isRequired
}

export default connect(null, { clearMessages })(Aboutpage);