import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../css/Layout.css';

class Tokendetails extends Component {
    render() {
        let renderError;
        if(this.props.error !== null){
            renderError = <div className="ErrorMessage"><label>{this.props.error}</label></div>;
        }

        let token;
        if(this.props.selectedToken !== null){
            token = this.props.selectedToken;
        }
        else{
            token = {
                goalUsername: '',
                count: 0,
                tierName: ''
            }
        }

        return (
            <div>
                {renderError}
                <h1 className="SlimHeader">Details</h1>
                <div className="DetailsLabel">
                    <label>Goal User</label>
                </div>
                <div className="DetailsSingleLine">
                    <label>{token.goalUsername}</label>
                </div>
                <div className="DetailsLabel">
                    <label>Amount</label>
                </div>
                <div className="DetailsSingleLine">
                    <label>{token.count}</label>
                </div>
                <div className="DetailsLabel">
                    <label>Tier</label>
                </div>
                <div className="DetailsSingleLine">
                    <label>{token.tierName.charAt(0).toUpperCase() + token.tierName.slice(1)}</label>
                </div>
            </div>
        )        
    }
}

Tokendetails.propTypes = {
    selectedToken: PropTypes.object
};

const mapStateToProps = state => ({
    selectedToken: state.tokens.selectedToken,
    error: state.tokens.selectedError
});

export default connect(mapStateToProps)(Tokendetails);