import { NEW_USER, VERIFY_USER, LOG_OUT, 
    CHANGE_PASSWORD, CLEAR_MESSAGES } from '../actions/types';
import { SETTINGS_TOKEN, SESSION_USERNAME, SESSION_USER_ID, 
    SESSION_USER_SUB_ID } from '../token';

const initialState = {
    username: '', 
    id: null,
    subId: null,
    newError: null,
    verifyError: null,
    changeError: null,
    successMessage: null
}

export default function(state = initialState, action) {
    // If this is one of our reponses
    if(action.payload !== undefined){
        // If this is one of our reponses
        if(action.payload.success !== undefined){
            // If the response was a success
            if(action.payload.success === true){
                // Every Dispatch is sent to every Reducer, only act on the Actions that concern this Reducer
                switch(action.type){
                    case NEW_USER:
                        return {...state, newError: null, successMessage: action.payload.message};
                    case CHANGE_PASSWORD:
                        return {...state, changeError: null};
                    case VERIFY_USER:
                        var userContent = JSON.parse(action.payload.payload);
                        localStorage.setItem(SETTINGS_TOKEN, userContent.newToken);
                        localStorage.setItem(SESSION_USERNAME, userContent.username);
                        localStorage.setItem(SESSION_USER_ID, userContent.id);
                        localStorage.setItem(SESSION_USER_SUB_ID, userContent.subId);
                        return {...state, username: userContent.username, id: userContent.id, subId: userContent.subId, verifyError: null};
                    case LOG_OUT:
                        localStorage.removeItem(SETTINGS_TOKEN);
                        localStorage.removeItem(SESSION_USERNAME);
                        localStorage.removeItem(SESSION_USER_ID);
                        localStorage.removeItem(SESSION_USER_SUB_ID);
                        return initialState;
                    // case CLEAR_MESSAGES: Reset all Error/Success messages but retain all other state
                    case CLEAR_MESSAGES:
                        return {...state, newError: null, verifyError: null, changeError: null, successMessage: null};
                    default:
                        return state;
                }
            }
            // Else handle the failed response
            else{
                // Every Dispatch is sent to every Reducer, only act on the Actions that concern this Reducer
                switch(action.type){
                    case NEW_USER:
                        return {...state, newError: action.payload.message, successMessage: null};
                    case VERIFY_USER:
                        return {...state, verifyError: action.payload.message};
                    case CHANGE_PASSWORD:
                        return {...state, changeError: action.payload.message};
                    case LOG_OUT:
                        return initialState;
                    default:
                        return state;
                }
            }
        }
    }
    // If browser Refresh or user manual URL naviation AND existing login details
    if(action.type === "@@INIT" && localStorage.getItem(SESSION_USERNAME) !== null) {
        // Retrieve the previous details from localStorage and put into state.
        return {...state, 
            username: localStorage.getItem(SESSION_USERNAME), 
            id: localStorage.getItem(SESSION_USER_ID), 
            subId: localStorage.getItem(SESSION_USER_SUB_ID),
            newError: null,
            verifyError: null,
            changeError: null,
            successMessage: null
        };
    }
    // The library first calls this function with a null action to retrieve the initial state
    return initialState;
}