import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import '../css/Footer.css';

import { CONTACT_PAGE, ABOUT_PAGE, CHANGES_PAGE } from '../pages.js';


class Footer extends Component {
    render() {
        let copyright = '\u00A9';

        return (
            <div className="FooterContainer">
                <div className="FooterLine"></div>
                <div className="FooterContent">
                    <div className="LeftFooter">
                        <div className="FItemSpacer"></div>
                        <label>COPYRIGHT{copyright} 2019 Wise Byte Inc. All Rights Reserved.</label>
                    </div>
                    <div className="RightFooter">
                        <div className="RFooterPadder">
                            <NavLink className="Pointer FooterClickLabel" to={`/` + CHANGES_PAGE}>What's New?</NavLink>
                            <div className="FItemSpacer"></div>
                            <NavLink className="Pointer FooterClickLabel" to={`/` + CONTACT_PAGE}>Contact</NavLink>                            
                            <div className="FItemSpacer"></div>
                            <NavLink className="Pointer FooterClickLabel" to={`/` + ABOUT_PAGE}>About</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );   
    }
}

export default connect(null)(Footer);