import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../../css/Usernav.css';

import Goalsanchor from './Goalsanchor';
import Rewardsanchor from './Rewardsanchor';
import Tokensanchor from './Tokensanchor';
import Browseanchor from './Browseanchor';

class Usernav extends Component {
    render() {
        return (
            <div className="UserNavigation">
                <div className="NavContainer">
                    <div className="NavDivider"></div>
                    <Browseanchor history={this.props.history}/>                
                    <div className="NavDivider"></div>
                    <Goalsanchor history={this.props.history}/>
                    <div className="NavDivider"></div>
                    <Tokensanchor history={this.props.history}/>
                    <div className="NavDivider"></div>
                    <Rewardsanchor history={this.props.history}/>
                    <div className="NavDivider"></div>
                </div>
            </div>
        );   
    }
}

export default connect(null)(Usernav);