import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../css/Layout.css';

import { resolveCompletion } from '../actions/completionActions';

class Completionoptions extends Component {
    constructor(props) {
        super(props);

        this.onClickResolve = this.onClickResolve.bind(this);
    }

    onClickResolve(completion) {
        let completionItem = this.props.completion;
        completionItem['resolution'] = completion;
        this.props.resolveCompletion(completionItem, this.props.user);
    }

    render() {
        let renderError;
        if(this.props.error !== null){
            renderError = <div className="ErrorMessage"><label>{this.props.error}</label></div>;
        }

        let renderItems;
        let completion = this.props.completion;
        let displayButtons = {"display": "block"};

        // If completion has not been responded to
        if(completion.confirmed === null) {
            // If this user is not the owner of the Goal associated to Completion
            if(this.props.user.id !== completion.setterId) {
                displayButtons = {"display": "none"};
                renderItems = <label>{completion.setterUsername} has not yet responded.</label>;
            }
        }
        else{
            displayButtons = {"display": "none"};
            if(completion.confirmed){
                // If this user is not the owner of the Goal associated to Completion
                if(this.props.user.id !== completion.setterId) {
                    renderItems = <label>{completion.setterUsername} confirmed this request.</label>;
                }
                else {
                    renderItems = <label>You confirmed this request.</label>;
                }
            }
            else {
                // If this user is not the owner of the Goal associated to Completion
                if(this.props.user.id !== completion.setterId) {
                    renderItems = <label>{completion.setterUsername} denied this request.</label>;
                }
                else {
                    renderItems = <label>You denied this request.</label>;
                }
            }
        }

        return (
            <div>
                <div style={displayButtons}>
                    {renderError}
                    <button className="OptionsConfirmRejectButton Pointer" onClick={() => this.onClickResolve(true)}>Confirm</button>
                    <div className="OptionsPadder"></div>
                    <button className="OptionsConfirmRejectButton Pointer" onClick={() => this.onClickResolve(false)}>Reject</button>
                </div>
                {renderItems}
            </div>
        )
    }
}

Completionoptions.propTypes = {
    resolveCompletion: PropTypes.func.isRequired,
    completion: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    user: state.user,
    error: state.completions.completeError
});

export default connect(mapStateToProps, { resolveCompletion })(Completionoptions);