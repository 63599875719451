import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';

import Newuserform from '../forms/Newuserform';
import { LOGIN_PAGE } from '../../pages';
import { clearMessages } from '../../actions/userActions';

class Createuserpage extends Component {
    constructor(props) {
        super(props);

        this.onClickReturningLogin = this.onClickReturningLogin.bind(this);
    }

    componentWillMount() {
        // Clear any previous Error/Success messages on page load
        this.props.clearMessages();
    }
    
    componentDidMount() {
        // Scroll to top of the page
        window.scrollTo(0, 0);
    }

    onClickReturningLogin() {
        this.props.history.push('/' + LOGIN_PAGE);
    }

    render() {
        return (
            <div className="White ContentArea">
                <div className="ContentPadder">
                    <div className="LeftRightSmall">
                        <div>
                            <h1>Create an Account</h1>
                            <p>
                                Etiam placerat maximus leo, vitae mattis enim convallis at. Semper eget faucibus id, suscipit varius velit. Aenean nec lorem et est tempus tincidunt vel non odio. Ut gravida condimentum justo eu sagittis. Donec vehicula suscipit libero, non ultrices nisi. Sed tincidunt turpis at tortor euismod maximus.
                            </p> 
                        </div>
                    </div>
                    <div className="LeftRightBig">
                        <Newuserform />
                        <div className="TogglePageArea">
                            <label className="Pointer TogglePageLabel" onClick={() => this.onClickReturningLogin()}>Returning user?</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Createuserpage.propTypes = {
    clearMessages: PropTypes.func.isRequired
};

export default connect(null, { clearMessages })(Createuserpage);