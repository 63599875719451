import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';
import '../../css/Accountpage.css';

import Resetpassform from '../forms/Resetpassform';
import { subscribe, clearMessages } from '../../actions/userActions';
import { fetchSubscriptionTiers } from '../../actions/subscriptionActions';

class Accountpage extends Component {
    constructor(props) {
        super(props);

        this.onClickSubscribe = this.onClickSubscribe.bind(this);
    }

    componentWillMount() {
        // Clear any previous Error/Success messages on page load
        this.props.clearMessages();

        this.props.fetchSubscriptionTiers();
    }

    componentDidMount() {
        // Scroll to top of the page
        window.scrollTo(0, 0);
    }

    onClickSubscribe() {
        const user = {
            username: this.props.user.username
        };

        this.props.subscribe(user);
    }
    
    render() {
        let user = this.props.user;
        let subList = this.props.subscriptionList;
        let subTitle;
        
        subList.forEach(function(element) {
            if(element.id === user.subId) {
                let title = element.title.charAt(0).toUpperCase() + element.title.slice(1);
                subTitle = <label>{title}</label>;
            }
        });

        return (
            <div className="White ContentArea">
                <div className="ContentPadder AccountPanel">
                    <h1 className="SlimHeader">Account Details</h1>
                    <div className="DetailsLabel">
                        <label>Username</label>
                    </div>
                    <div className="DetailsSingleLine">
                        <label>{user.username}</label>
                    </div>
                    <div className="DetailsLabel">
                        <label>Subscription Tier</label>
                    </div>
                    <div className="DetailsSingleLine">
                        {subTitle}
                    </div>
                    <label className="Pointer UserFormSubmitButton SubscribeButton" onClick={() => this.onClickSubscribe()}>Subscribe</label>
                    <div className="DetailsLabel">
                        <label>Recovery Email Address</label>
                    </div>
                    <div className="DetailsSingleLine">
                        <label>Coming Soon</label>
                    </div>
                    <div className="DetailsLabel">
                        <label>Reset Password</label>
                    </div>
                    <Resetpassform />
                </div>
            </div>
        );
    }
}

Accountpage.propTypes = {
    user: PropTypes.object.isRequired,
    subscriptionList: PropTypes.array.isRequired,
    fetchSubscriptionTiers : PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    user: state.user,
    subscriptionList: state.subscriptions.subList
});

export default connect(mapStateToProps, { fetchSubscriptionTiers, 
    subscribe, clearMessages })(Accountpage);