import React, { Component } from 'react';
import { connect } from 'react-redux';

import '../css/Layout.css';

import Goalcompleter from './Goalcompleter';

class Goaldetails extends Component {
    render() {
        let renderError;
        if(this.props.error !== null){
            renderError = <div className="ErrorMessage CompleterErrorMessage"><label>{this.props.error}</label></div>;
        }

        let goal;
        if(this.props.selectedGoal !== null){
            goal = this.props.selectedGoal;
        }
        else {
            goal = {
                title: '',
                description: '',
                tierName: '',
                limitCount: 0,
                pendingCount: 0,
                count: 0
            };
        }

        let available = 'Unlimited';
        if(goal.limitCount !== null){
            available = goal.limitCount - (goal.count + goal.pendingCount);
        };
        if(goal.complete){
            available = 'None';
        }

        return (
            <div>
                {renderError}
                <h1 className="SlimHeader">Details</h1>
                <div className="DetailsTitle">
                    <label>{goal.title}</label>
                </div>
                <div className="DetailsDescription">
                    <label>{goal.description}</label>
                </div>
                <div className="DetailsLabel">
                    <label>Tier</label>
                </div>
                <div className="DetailsSingleLine">
                    <label>{goal.tierName.charAt(0).toUpperCase() + goal.tierName.slice(1)}</label>
                </div>
                <div className="DetailsLabel">
                    <label>Completions available</label>
                </div>
                <div className="DetailsSingleLine">
                    <label>{available} ({goal.pendingCount} Pending)</label>
                </div>
                <Goalcompleter />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    selectedGoal: state.goals.selectedGoal,
    error: state.goals.selectedError
});

export default connect(mapStateToProps)(Goaldetails);