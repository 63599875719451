import { logOut } from '../../actions/userActions';

export function handleError(error, dispatch, originalAction) {
    // If 401, probably user token has expired, dispatch 'logout' action
    if(error.message === "401"){
        dispatch(logOut());
    }
    // Else return the error to the reducer
    else {
        dispatch({
            type: originalAction,
            payload: {
                success: false,
                message: 'Failed to contact server: ' + error.message
            }
        });
    };
}