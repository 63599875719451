import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';

import { clearMessages } from '../../actions/userActions';

class Contactpage extends Component {
    componentWillMount() {
        // Clear any previous Error/Success messages on page load
        this.props.clearMessages();
    }

    componentDidMount() {
        // Scroll to top of the page
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <div className="White ContentArea">
                <div className="ContentPadder">
                    <h1 className="SlimHeader">Contact the Goal Get 'Em team</h1>
                    <p>
                        Maecenas pulvinar, velit in pellentesque scelerisque, odio neque aliquet velit, ut tempus tellus felis id quam. In varius feugiat tempus. Praesent lacinia ipsum neque, ac feugiat lectus malesuada sollicitudin. Integer quis aliquam nibh, in dapibus tortor. Nam quis est velit. Suspendisse sed enim feugiat, gravida purus a, cursus dui.
                    </p>
                    <p>contact@notyet.com</p>
                </div>
            </div>
        );
    }
}

Contactpage.propTypes = {
    clearMessages: PropTypes.func.isRequired
}

export default connect(null, { clearMessages })(Contactpage);