export const FETCH_GOALS = 'FETCH_GOALS';
export const BROWSE_GOALS = 'BROWSE_GOALS';
export const NEW_GOAL = 'NEW_GOAL';
export const FETCH_GOAL = 'FETCH_GOAL';
export const COMPLETE_GOAL = 'COMPLETE_GOAL';
export const DOWN_GOAL = 'DOWN_GOAL';

export const SET_PAGE = 'SET_PAGE';

export const NEW_USER = 'NEW_USER';
export const VERIFY_USER = 'VERIFY_USER';
export const LOG_OUT = 'LOG_OUT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const FETCH_OWN_REWARDS = 'FETCH_OWN_REWARDS';
export const CREATE_REWARD = 'CREATE_REWARD';
export const FETCH_ELIGIBLE_REWARDS = 'FETCH_ELIGIBLE_REWARDS';
export const REWARD_DETAILS = 'REWARD_DETAILS';
export const FETCH_GOAL_REWARDS = 'FETCH_GOAL_REWARDS';
export const TAKE_DOWN_REWARD = 'TAKE_DOWN_REWARD';
export const BROWSE_REWARDS = 'BROWSE_REWARDS';

export const FETCH_OWN_TOKENS = 'FETCH_OWN_TOKENS';
export const REDEEM_TOKEN =  'REDEEM_TOKEN';
export const SET_TOKEN = 'SET_TOKEN';
export const FETCH_IDLE_TOKENS = 'FETCH_IDLE_TOKENS';
export const RECLAIM_TOKEN = 'RECLAIM_TOKEN';

export const FETCH_TIERS = 'FETCH_TIERS';
export const SELECT_TIER = 'SELECT_TIER';

export const FETCH_REDEMPTION_REQUESTS = 'FETCH_REDEMPTION_REQUESTS';
export const RESOLVE_REDEMPTION = 'RESOLVE_REDEMPTION';
export const FETCH_SENT_REDEMPTIONS = 'FETCH_SENT_REDEMPTIONS';

export const FETCH_COMPLETION_REQUESTS = 'FETCH_COMPLETION_REQUESTS';
export const RESOLVE_COMPLETION = 'RESOLVE_COMPLETION';
export const FETCH_COMPLETION_ATTEMPTS = 'FETCH_COMPLETION_ATTEMPTS';

export const FETCH_SUBS = 'FETCH_SUBS';

export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';