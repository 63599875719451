import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';
import '../../css/Tokenspage.css';

import { SUB_NONE, SUB_MY_TOKENS, SUB_OUT_TOKENS } from '../../pages';
import Tokens from '../hzontals/Tokens';
import { fetchRewardTokens, fetchIdleTokens, 
    reclaimTokens  } from '../../actions/tokenActions';
import { clearMessages } from '../../actions/userActions';

class Tokenspage extends Component {
    constructor(props) {
        super(props);

        this.onClickResolve = this.onClickReclaimTokens.bind(this);

        this.tokensRef = React.createRef();
        this.outsRef = React.createRef();
        // Prevents render from moving page except by navigation
        this.previousSubPage = '';
    }

    componentWillMount() {
        // Clear any previous Error/Success messages on page load
        this.props.clearMessages();

        this.props.fetchRewardTokens(this.props.user);
        this.props.fetchIdleTokens(this.props.user);
    }

    componentDidMount() {
        this.moveWindowPosition();
    }

    moveWindowPosition() {
        // If refs have been placed on page
        if(this.tokensRef.current !== null){
            let topOffset = 0;
            switch(this.props.subPage){
                case SUB_MY_TOKENS:
                    topOffset = this.tokensRef.current.offsetTop;
                    break;
                case SUB_OUT_TOKENS:
                    topOffset = this.outsRef.current.offsetTop;
                    break;
                case SUB_NONE:
                default:
                    break;
            }
            window.scrollTo(0, topOffset);
        }
    }

    onClickReclaimTokens() {
        this.props.reclaimTokens(this.props.user);
    }

    render() {
        let renderError;
        if(this.props.reclaimError !== null){
            renderError = [<div className="ErrorMessage ReclaimError" key='1'><label>{this.props.reclaimError}</label></div>, <br key='2'/>];
        }

        // Prevents re-render from moving page
        if(this.previousSubPage !== this.props.subPage){
            this.moveWindowPosition();
            this.previousSubPage = this.props.subPage;
        }

        return (
            <div>
                <div className="White ContentArea" ref={this.tokensRef}>
                    <div className="ContentPadder">
                        <div className="LeftRightSmall MyTokensArea">
                            <h1 className="SlimHeader">My Tokens</h1> 
                            <p>
                                Proin neque mauris, faucibus et nisi eget, facilisis varius enim. Maecenas at elementum dolor. Etiam placerat maximus leo, vitae mattis enim convallis at. Nullam eleifend ligula urna, luctus pharetra est fermentum sit amet. Suspendisse urna urna, semper eget faucibus id, suscipit varius velit. Aenean nec lorem et est tempus tincidunt vel non odio. Ut gravida condimentum justo eu sagittis. Donec vehicula suscipit libero, non ultrices nisi. Sed tincidunt turpis at tortor euismod maximus.
                            </p>    
                        </div>
                        <div className="LeftRightBig">
                            <Tokens history={this.props.history} tokenList={this.props.tokenList} error={this.props.ownListError}/>
                        </div>
                    </div>
                </div>
                <div className="Purple ContentArea" ref={this.outsRef}>
                    <div className="ContentPadder">
                        <div className="LeftRightBig">
                            <Tokens history={this.props.history} tokenList={this.props.outsList} error={this.props.outsListError}/>
                        </div>
                        <div className="LeftRightSmall RewardTokensArea">
                            <h1 className="SlimHeader">Outstanding Tokens</h1> 
                            <p>
                                Maecenas at elementum dolor. Etiam placerat maximus leo, vitae mattis enim convallis at. Nullam eleifend ligula urna, luctus pharetra est fermentum sit amet. Suspendisse urna urna, semper eget faucibus id, suscipit varius velit. Aenean nec lorem et est tempus tincidunt vel non odio. Ut gravida condimentum justo eu sagittis.
                            </p>
                            {renderError}
                            <button className="Pointer TokenReclaimButton" onClick={() => this.onClickReclaimTokens()}>Rescind Tokens</button> 
                        </div>                        
                    </div>
                </div>
            </div>
        );   
    }
}

Tokenspage.propTypes = {
    fetchRewardTokens: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    reclaimTokens: PropTypes.func.isRequired,
    fetchIdleTokens: PropTypes.func.isRequired,
    tokenList: PropTypes.array.isRequired,
    outsList: PropTypes.array.isRequired,
    clearMessages: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    user: state.user,
    tokenList: state.tokens.tokenList,
    outsList: state.tokens.outstandingList,
    ownListError: state.tokens.ownListError,
    outsListError: state.tokens.outsListError,
    reclaimError: state.tokens.reclaimError
});

export default connect(mapStateToProps, { fetchRewardTokens, 
    fetchIdleTokens, reclaimTokens, clearMessages })(Tokenspage);