import { FETCH_OWN_TOKENS, REDEEM_TOKEN, SET_TOKEN, 
    FETCH_IDLE_TOKENS, RECLAIM_TOKEN } from './types';
import { PROXY_URL, API_PATH } from '../config';
import { handleResponse } from './helpers/handleResponse';
import { handleError } from './helpers/handleError';
import { createAuthHeader } from './helpers/createHeader';

export function fetchRewardTokens(user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/OwnTokens', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(user)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: FETCH_OWN_TOKENS,
                payload: json
            })
        }).catch((error) => handleError(error, dispatch, FETCH_OWN_TOKENS));
    }
}

export function fetchIdleTokens(user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/IdleTokens', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(user)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: FETCH_IDLE_TOKENS,
                payload: json
            })
        }).catch((error) => handleError(error, dispatch, FETCH_IDLE_TOKENS));
    }
}

export function redeemToken(token, reward) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/RedeemToken', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify({...token, ...reward})
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: REDEEM_TOKEN,
                payload: json
            });
        }).catch((error) => handleError(error, dispatch, REDEEM_TOKEN));
    }
}

export function setToken(token) {
    return function(dispatch) {
        dispatch({
            type: SET_TOKEN,
            payload: {
                success: true,
                selectedToken: token
            }
        });
    }
}

export function reclaimTokens(user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/ReclaimTokens', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(user)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: RECLAIM_TOKEN,
                payload: json
            });
            dispatch(fetchIdleTokens(user));
        }).catch((error) => handleError(error, dispatch, RECLAIM_TOKEN));
    }
}