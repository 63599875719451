import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';
import '../../css/Resetpassform.css';

import { changePassword } from '../../actions/userActions';

class Resetpassform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            newPassword: ''
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();

        const user = {
            username: this.props.user.username,
            password: this.state.password,
            newPassword: this.state.newPassword
        };
        
        this.props.changePassword(user);

        //Clearing the form fields
        this.setState({
            password: '',
            newPassword: ''
        });
    }

    render() {
        let renderError;
        if(this.props.error !== null){
            renderError = <div className="ErrorMessage"><label>{this.props.error}</label></div>;
        }

        return (
            <form onSubmit={this.onSubmit}>
                <div className="ChangePasswordArea">
                    <label className="FormLabel">Old Password</label><br/>
                    <input type="password" name="password" className="PurpleBorder" onChange={this.onChange} value={this.state.password} /><br/>
                    <label className="FormLabel">New Password</label><br/>
                    <input type="password" name="newPassword" className="PurpleBorder" onChange={this.onChange} value={this.state.newPassword} />
                </div>
                {renderError}
                <button className="Pointer UserFormSubmitButton ChangePasswordButton" type="submit">Change</button>
            </form>
        ); 
    }
}

Resetpassform.propTypes = {
    changePassword: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    error: state.user.changeError
});

export default connect(mapStateToProps, { changePassword })(Resetpassform);