import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';
import '../../css/Hzontal.css';

import Redeemoptions from '../Redeemoptions';

class Redemptionrequests extends Component {
    constructor(props) {
        super(props);
        // Create a refrence to the div component that needs to be scrolled horizontally
        this.horizontalRef = React.createRef();
        this.onClickScroll = this.onClickScroll.bind(this);
    }

    onClickScroll(amount) {
        this.horizontalRef.current.scrollLeft += amount;
    }

    render() {
        let displayError = {"display": "none"};
        if(this.props.error !== null){
            displayError = {"display": "inline-block"};
        }

        // Scroll button stuff
        let sizeOfHzontalItem = 720;
        let leftArrow = "<";
        let rightArrow = ">";
        let displayScrollButtons = {"display": "inline-block"};
        if(this.props.redemptionList.length <= 1) {
            displayScrollButtons = {"display": "none"};
        }

        let redemptionItems = this.props.redemptionList.map(redemption => {
            let displayOwner = {"display": "block"};
            let displayRewardOwner = {"display": "block"};

            //If this request belongs to current user
            if(redemption.redeemUserId === this.props.user.id){
                displayOwner = {"display": "none"};
            }

            //If this reward belongs to current user
            if(redemption.rewardUserId === this.props.user.id){
                displayRewardOwner = {"display": "none"};
            }

            return <div className="HorizontalListItem" key={redemption.id}>
                        <div className="HzontalTitleArea HzontalTopItem">
                            <label>{redemption.rewardTitle}</label>
                        </div>
                        <div className="HzontalLabelArea" style={displayOwner}>
                            <label>Requested By</label>
                        </div>
                        <div className="HzontalUserArea" style={displayOwner}>
                            <label>{redemption.redeemUsername}</label>   
                        </div>
                        <div className="HzontalLabelArea" style={displayRewardOwner}>
                            <label>Requested From</label>
                        </div>
                        <div className="HzontalUserArea" style={displayRewardOwner}>
                            <label>{redemption.rewardUsername}</label>   
                        </div>
                        <div className="HzontalLabelArea">
                            <label>Message</label>
                        </div>
                        <div className="HzontalMessageArea">
                            <label>{redemption.message}</label>
                        </div>
                        <Redeemoptions redemption={redemption}/>
                    </div>;
        });

        return (
            <div className="HzontalPanelArea">
                <div className="HzontalScrollArea">
                    <button className="Pointer HzontalScrollButton" style={displayScrollButtons} onClick={() => this.onClickScroll(-sizeOfHzontalItem)}>{leftArrow}</button>
                </div>
                <div className="HorizontalList" ref={this.horizontalRef}>                    
                    <div className="HorizontalListItem ErrorMessage HzontalTopItem" style={displayError}>
                        <label>{this.props.error}</label>
                    </div>
                    {redemptionItems}
                </div>
                <div className="HzontalScrollArea" style={displayScrollButtons}>
                    <button className="Pointer HzontalScrollButton" onClick={() => this.onClickScroll(sizeOfHzontalItem)}>{rightArrow}</button>
                </div>
            </div>
        )
    }
}

Redemptionrequests.propTypes = {
    user: PropTypes.object.isRequired,
    error: PropTypes.string
};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(Redemptionrequests);