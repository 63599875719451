import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';
import '../../css/Hzontal.css';

import Goaldetails from '../Goaldetails';
import Rewards from '../hzontals/Rewards.js';
import { clearMessages } from '../../actions/userActions';

class Goaldetailspage extends Component {
    componentWillMount() {
        // Clear any previous Error/Success messages on page load
        this.props.clearMessages();
    }

    componentDidMount() {
        // Scroll to top of the page
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <div className="White ContentArea">
                <div className="ContentPadder">
                    <div className="LeftRightSmall">
                        <Goaldetails />
                    </div>
                    <div className="LeftRightBig">
                        <h1 className="HzontalHeader">Possible Rewards</h1>
                        <Rewards history={this.props.history}/>
                    </div>
                </div>
            </div>
        );   
    }
}

Goaldetailspage.propTypes = {
    clearMessages: PropTypes.func.isRequired
}

export default connect(null, { clearMessages })(Goaldetailspage);