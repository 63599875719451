import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';

import Loginform from '../forms/Loginform';

import { CREATE_USER_PAGE } from '../../pages';
import { clearMessages } from '../../actions/userActions';

class Loginpage extends Component {
    constructor(props) {
        super(props);

        this.onClickCreateUser = this.onClickCreateUser.bind(this);
    }

    componentWillMount() {
        // Clear any previous Error/Success messages on page load
        this.props.clearMessages();
    }

    componentDidMount() {
        // Scroll to top of the page
        window.scrollTo(0, 0);
    }

    onClickCreateUser() {
        this.props.history.push('/' + CREATE_USER_PAGE);
    }

    render() {
        return (
            <div className="LoginUserArea White ContentArea">
                <div className="ContentPadder">
                    <div className="LeftRightSmall">
                        <div>
                            <h1>Welcome Back</h1>
                            <p>
                                Etiam placerat maximus leo, vitae mattis enim convallis at. Semper eget faucibus id, suscipit varius velit. Aenean nec lorem et est tempus tincidunt vel non odio. Ut gravida condimentum justo eu sagittis. Donec vehicula suscipit libero, non ultrices nisi. Sed tincidunt turpis at tortor euismod maximus.
                            </p> 
                        </div>
                    </div>
                    <div className="LeftRightBig">
                        <Loginform />
                        <div className="TogglePageArea">
                            <label className="Pointer TogglePageLabel" onClick={() => this.onClickCreateUser()}>New user?</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Loginpage.propTypes = {
    clearMessages: PropTypes.func.isRequired
};

export default connect(null, { clearMessages })(Loginpage);