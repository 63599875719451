import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { redeemToken } from '../actions/tokenActions';
import { takeDownReward } from '../actions/rewardActions';

class Rewardclaimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ''
        }

        this.onChange = this.onChange.bind(this);
        this.onClickClaim = this.onClickClaim.bind(this);
        this.onClickTakeDown = this.onClickTakeDown.bind(this);
    }

    componentDidUpdate(prevProps) {
        // If the User succceeds in Claiming a Reward
        if (this.props.selectedToken !== null && this.props.selectedToken.count !== prevProps.selectedToken.count) {
            // Empty the message box
            this.setState({message: ''});
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onClickClaim(token, reward) {
        let passToken = {
            tokenId: token.id
        }

        let passReward = {
            rewardId: reward.id,
            redeemMessage: this.state.message
        }

        this.props.redeemToken(passToken, passReward);
    }

    onClickTakeDown(reward, user) {
        let passReward = {
            rewardId: reward.id,
            completeMessage: this.state.message,
            userId: user.id
        }

        this.props.takeDownReward(passReward);
    }

    render() {
        let renderRewardError;
        if(this.props.rewardError !== null){
            renderRewardError = <div className="ErrorMessage"><label>{this.props.rewardError}</label></div>;
        }

        let renderTokenError;
        if(this.props.tokenError !== null){
            renderTokenError = <div className="ErrorMessage"><label>{this.props.tokenError}</label></div>;
        }

        let renderSuccess;
        if(this.props.success !== null){
            renderSuccess = <div className="SuccessMessage"><label>{this.props.success}</label></div>;
        }

        let reward;
        if(this.props.selectedReward !== null){
            reward = this.props.selectedReward;
        }
        else {
            reward = {
                ownerId: 0,
                id: 0
            };
        }

        let user = this.props.user;
        
        let token;
        if(this.props.selectedToken !== null){
            token = this.props.selectedToken;
        }
        else {
            token = {
                ownerId: 0,
                goalUserId: 0,
                id: 0
            };
        }
        
        let displayClaim = {"display": "none"};
        let displayTakeDown = {"display": "none"};
        //Check for reward ownership
        if(user.id === reward.ownerId){
            if(!reward.complete){
                displayTakeDown = {"display": "block"};
            }
        }
        else {
            //If there is no token
            if(token.id !== 0){
                //Check for token availability (user owns this token && token was granted by rewards setter)
                if(user.id === token.ownerId && token.goalUserId === reward.ownerId){
                    displayClaim = {"display": "block"};
                }
            }
        }

        return (
            <div>
                <div style={displayClaim}>
                    <div className="DetailsLabel">
                        <label>Include Message</label>
                    </div>
                    <div className="DetailsDescription">
                        <textarea className="PurpleBorder SizedMessageTextarea" name="message" onChange={this.onChange} value={this.state.message} />
                    </div>
                    {renderTokenError}{renderSuccess}
                    <button className="Pointer DetailsSubmitButton" onClick={() => this.onClickClaim(token, reward)}>Claim</button>      
                </div>
                <div style={displayTakeDown}>
                    <div className="DetailsLabel">
                        <label>Include Message</label>
                    </div>
                    <div className="DetailsDescription">
                        <textarea className="PurpleBorder SizedMessageTextarea" name="message" onChange={this.onChange} value={this.state.message} />
                    </div>
                    {renderRewardError}
                    <button className="Pointer DetailsSubmitButton" onClick={() => this.onClickTakeDown(reward, user)}>Take Down</button>      
                </div>
            </div>
        );
    }
}

Rewardclaimer.propTypes = {
    redeemToken: PropTypes.func.isRequired,
    takeDownReward: PropTypes.func.isRequired,
    selectedReward: PropTypes.object,
    selectedToken: PropTypes.object,
    user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    selectedReward: state.rewards.selectedReward,
    selectedToken: state.tokens.selectedToken,
    user: state.user,
    rewardError: state.rewards.downError,
    tokenError: state.tokens.redeemError,
    success: state.tokens.successMessage
});

export default connect(mapStateToProps, { redeemToken, takeDownReward })(Rewardclaimer);