import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Newrewardform.css';
import '../../css/Layout.css';

import { createReward } from '../../actions/rewardActions'
import Tierselection from '../Tierselection';

class Newrewardform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            count: 1,
            unlimited: false
        }

        this.onChange = this.onChange.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        // If the User succceeds in Creating a Reward
        if (this.props.success !== prevProps.success && this.props.success != null) {
            // Clearing the form fields
            this.setState({
                title: '',
                description: '',
                count: 1,
                unlimited: false
            });
        }
        // Else If the User succeeds in Creating a Reward after already succeeding.
        else if (this.props.success != null && this.props.rewardList.length !== prevProps.rewardList.length) {
            // Clearing the form fields
            this.setState({
                title: '',
                description: '',
                count: 1,
                unlimited: false
            });
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onCheck(e) {
        this.setState({[e.target.name]: e.target.checked});
    }

    onSubmit(e) {
        e.preventDefault();
        let user = this.props.user;
        
        const reward = {
            title: this.state.title,
            description: this.state.description,
            ownerId: user.id,
            tier: this.props.selectedTier,
            count: this.state.count,
            unlimited: this.state.unlimited
        };

        this.props.createReward(reward, user);
    }

    render() {
        let renderError;
        if(this.props.error !== null){
            renderError = <div className="ErrorMessage"><label>{this.props.error}</label></div>;
        }

        let renderSuccess;
        if(this.props.success !== null){
            renderSuccess = <div className="SuccessMessage"><label>{this.props.success}</label></div>;
        }

        let displayLimited;
        if(this.state.unlimited === true){
            displayLimited = {"backgroundColor": "grey"};
        }

        return (
            <div className="RewardForm">
                <form onSubmit={this.onSubmit}>
                    <div className="NewRewardTitleArea">
                        <label className="FormLabel Bold">Title</label>
                        <br/>
                        <input type="text" name="title" className="PurpleBorder NewRewardTitleInput" onChange={this.onChange} value={this.state.title} />
                    </div>
                    <div className="NewRewardCompArea">
                        <label className="FormLabel Bold">Number Available</label>
                        <br /><br />
                        <div className="RewardColumnContainer">
                            <div className="NewRewardColumn">
                                <label className="FormLabel NewRewardQuantLabel">Quantity</label>
                                <input type="number" name="count" className="NewRewardCounter PurpleBorder" onChange={this.onChange} value={this.state.count} style={displayLimited} />
                            </div>
                            <div className="NewRewardColumn">
                                <input type="checkbox" className="Pointer NewRewardCkBx" name="unlimited" id="unlimitedCheck" onChange={this.onCheck} checked={this.state.unlimited} />
                                <label htmlFor="unlimitedCheck" className="FormLabel Pointer">Unlimited</label>
                                <br />
                            </div>
                        </div>
                    </div>
                    <div className="NewRewardTierArea">
                        <label className="FormLabel Bold">Tier</label>
                        <br />
                        <Tierselection />
                    </div>
                    <div className="NewRewardDescArea">
                        <label className="FormLabel Bold">Description</label>
                        <br />
                        <textarea name="description" className="PurpleBorder NewRewardDescInput" onChange={this.onChange} value={this.state.description} />
                    </div>
                    {renderError}{renderSuccess}
                    <div className="NewRewardSubmitArea">
                        <button className="Pointer NewRewardSubButton" type="submit">Create Reward</button>
                    </div>
                </form>
            </div>
        ); 
    }
}

Newrewardform.propTypes = {
    createReward: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    selectedTier: PropTypes.number.isRequired,
    error: PropTypes.string
};

const mapStateToProps = state => ({
    user: state.user,
    selectedTier: state.tiers.selectedTier,
    error: state.rewards.formError,
    success: state.rewards.successMessage,
    rewardList: state.rewards.rewardList
});

export default connect(mapStateToProps, { createReward })(Newrewardform);