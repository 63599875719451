import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Layout from './components/pages/Layout';

import store from './store';

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router>
                    <Route path="/:filter?/:subFilter?/:unusedFilter?" component={Layout} />
                </Router>
            </Provider>
        );   
    }
}

export default App;