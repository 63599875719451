import { FETCH_OWN_TOKENS, REDEEM_TOKEN, SET_TOKEN,
    FETCH_IDLE_TOKENS, RECLAIM_TOKEN, CLEAR_MESSAGES } from '../actions/types';

const initialState = {
    tokenList: [],
    outstandingList: [],
    selectedToken: null,
    ownListError: null,
    outsListError: null,
    selectedError: null,
    redeemError: null,
    reclaimError: null,
    successMessage: null
}

export default function(state = initialState, action) {
    // If this is one of our reponses
    if(action.payload !== undefined){
        // If this is one of our reponses
        if(action.payload.success !== undefined){
            // If the response was a success
            if(action.payload.success === true){
                // Every Dispatch is sent to every Reducer, only act on the Actions that concern this Reducer
                switch(action.type){
                    case FETCH_OWN_TOKENS:
                        return {...state, tokenList: JSON.parse(action.payload.payload), ownListError: null};
                    case FETCH_IDLE_TOKENS:
                        return {...state, outstandingList: JSON.parse(action.payload.payload), outsListError: null};
                    case RECLAIM_TOKEN:
                        return {...state, reclaimError: null}
                    case REDEEM_TOKEN:
                        return {...state, selectedToken: JSON.parse(action.payload.payload), redeemError: null, successMessage: action.payload.message};
                    case SET_TOKEN:
                        return {...state, selectedToken: action.payload.selectedToken, selectedError: null};
                    // case CLEAR_MESSAGES: Reset all Error/Success messages but retain all other state
                    case CLEAR_MESSAGES:
                        return {...state, ownListError: null, outsListError: null, selectedError: null, 
                            redeemError: null, reclaimError: null, successMessage: null};
                    default:
                        return state;
                }
            }
            // Else handle the failed response
            else{
                // Every Dispatch is sent to every Reducer, only act on the Actions that concern this Reducer
                switch(action.type){
                    case FETCH_OWN_TOKENS:
                        return {...state, tokenList: [], ownListError: action.payload.message};
                    case FETCH_IDLE_TOKENS:
                        return {...state, outstandingList: [], outsListError: action.payload.message};
                    case REDEEM_TOKEN:
                        return {...state, redeemError: action.payload.message, successMessage: null};
                    case RECLAIM_TOKEN:
                        return {...state, reclaimError: action.payload.message};
                    case SET_TOKEN:
                        return {...state, selectedError: action.payload.message};
                    default:
                        return state;
                }
            }
        }             
    }
    // The library first calls this function with a null action to retrieve the initial state
    return initialState;
}