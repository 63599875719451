import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const initialState = {};

const middleware = [thunk];

let devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

// Relpaces null when the target browser doesn't have ReduxDevTools extension
if (window.__REDUX_DEVTOOLS_EXTENSION__ == null) {
    devTools = a => a;
}

const store = createStore(
    rootReducer, 
    initialState,
    compose(
        applyMiddleware(...middleware),
        devTools
    )    
);

export default store;