import { FETCH_COMPLETION_REQUESTS, RESOLVE_COMPLETION, FETCH_COMPLETION_ATTEMPTS } from './types';
import { PROXY_URL, API_PATH } from '../config';
import { handleResponse } from './helpers/handleResponse';
import { handleError } from './helpers/handleError';
import { createAuthHeader } from './helpers/createHeader';

import { fetchGoals } from './goalActions';

export function fetchCompletionRequests(user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/CompletionRequests', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(user)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: FETCH_COMPLETION_REQUESTS,
                payload: json
            })
        }).catch((error) => handleError(error, dispatch, FETCH_COMPLETION_REQUESTS));
    }
}

export function fetchCompletionAttempts(user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/CompletionAttempts', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(user)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: FETCH_COMPLETION_ATTEMPTS,
                payload: json
            })
        }).catch((error) => handleError(error, dispatch, FETCH_COMPLETION_ATTEMPTS));
    }
}

export function resolveCompletion(completion, user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/ResolveCompletion', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(completion)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: RESOLVE_COMPLETION,
                payload: json
            })
            //Also dispatch the fetchCompletionRequests/fetchGoals methods so our lists refresh
            dispatch(fetchCompletionRequests(user));
            dispatch(fetchGoals(user));
        }).catch((error) => handleError(error, dispatch, RESOLVE_COMPLETION));
    }
}