import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Browsefilterform.css';
import '../../css/Layout.css';

import { browseGoalsByTitle, browseGoalsByUsername } from '../../actions/goalActions';
import { browseRewardsByTitle } from '../../actions/rewardActions';

class Browsefilterform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            searchKey: 'title'
        }

        this.onChange = this.onChange.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onChangeSearch(e) {
        this.setState({searchKey: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();

        let user = {
            userId: this.props.user.id
        }

        if(this.state.searchKey === 'title'){
            let searchItem = {
                title: this.state.searchValue
            };

            //Submit the search to actions
            switch(this.props.filterType) {
                case 'rewards':
                    this.props.browseRewardsByTitle(searchItem, user);
                    break;
                case 'goals':
                default:
                    this.props.browseGoalsByTitle(searchItem, user);
                    break;
            }
        }
        else{
            let searchUser = {
                searchUser: this.state.searchValue
            };
            
            //Submit the search to actions
            switch(this.props.filterType) {
                case 'rewards':
                    break;
                case 'goals':
                default:
                    this.props.browseGoalsByUsername(searchUser, user);
                    break;
            }
        }
    }

    render() {
        let renderError;
        if(this.props.error !== null){
            renderError = <div className="ErrorMessage BrowseErrorMessage"><label>{this.props.error}</label></div>;
        }

        let renderOptions;
        switch(this.props.filterType) {
            case 'rewards':
                renderOptions = [<option value="title" key="1">Title</option>]
                break;
            case 'goals':
            default:
                renderOptions = [<option value="title" key="1">Title</option>, <option value="username"  key="2">Username</option>]
                break;
        }

        return (
            <form onSubmit={this.onSubmit} className="BrowseFormArea">
                <div className="BrowseByArea">
                    <label className="FormLabel">Filter By</label>
                    <br />
                    <select value={this.state.searchKey} onChange={this.onChangeSearch} className="PurpleBorder BrowseFilterBySelect">
                        {renderOptions}
                    </select>
                </div>
                <label className="FormLabel">Search For</label>
                <br />
                <input type="text" name="searchValue" className="SearchValue PurpleBorder" onChange={this.onChange} value={this.state.searchValue} />
                {renderError}
                <button type="submit" className="BrowseFilterSubButton Pointer">Search</button>
            </form> 
        ); 
    }
}

Browsefilterform.propTypes = {
    browseGoalsByTitle: PropTypes.func.isRequired,
    browseGoalsByUsername: PropTypes.func.isRequired,
    browseRewardsByTitle: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, { browseGoalsByTitle, browseGoalsByUsername, browseRewardsByTitle } )(Browsefilterform);