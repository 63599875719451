import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../css/Layout.css';

import { resolveRedemption } from '../actions/redemptionActions';

class Redeemoptions extends Component {
    constructor(props) {
        super(props);

        this.onClickResolve = this.onClickResolve.bind(this);
    }

    onClickResolve(resolution) {
        let redemptionItem = this.props.redemption;
        redemptionItem['resolution'] = resolution;
        this.props.resolveRedemption(redemptionItem, this.props.user);
    }

    render() {
        let renderError;
        if(this.props.error !== null){
            renderError = <div className="ErrorMessage"><label>{this.props.error}</label></div>;
        }

        let renderItems;
        let redemption = this.props.redemption;
        let displayButtons = {"display": "block"};

        // If user has not responded
        if(redemption.confirmed === null) {
            // If this user is not the owner of the Reward associated to the Redemption
            if(this.props.user.id !== redemption.rewardUserId) {
                displayButtons = {"display": "none"};
                renderItems = <label>{redemption.rewardUsername} has not yet responded.</label>;
            }
        }
        else{
            displayButtons = {"display": "none"};
            if(redemption.confirmed){
                // If this user is not the owner of the Reward associated to the Redemption
                if(this.props.user.id !== redemption.rewardUserId) {
                    renderItems = <label>{redemption.rewardUsername} confirmed this request.</label>;
                }
                else {
                    renderItems = <label>You confirmed this request.</label>;
                }
            }
            else {
                // If this user is not the owner of the Reward associated to the Redemption
                if(this.props.user.id !== redemption.rewardUserId) {
                    renderItems = <label>{redemption.rewardUsername} denied this request.</label>;
                }
                else {
                    renderItems = <label>You denied this request.</label>;
                }
            }
        }

        return (
            <div>
                <div style={displayButtons}>
                    {renderError}
                    <button className="OptionsConfirmRejectButton Pointer" onClick={() => this.onClickResolve(true)}>Confirm</button>
                    <div className="OptionsPadder"></div>
                    <button className="OptionsConfirmRejectButton Pointer" onClick={() => this.onClickResolve(false)}>Reject</button>
                </div>
                {renderItems}
            </div>
        )
    }
}

Redeemoptions.propTypes = {
    resolveRedemption: PropTypes.func.isRequired,
    redemption: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    user: state.user,
    error: state.redemptions.resolveError
});

export default connect(mapStateToProps, { resolveRedemption })(Redeemoptions);