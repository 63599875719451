import { FETCH_GOALS, NEW_GOAL, BROWSE_GOALS, COMPLETE_GOAL,
    FETCH_GOAL, DOWN_GOAL, CLEAR_MESSAGES } from '../actions/types';

const initialState = {
    goalList: [],
    selectedGoal: null,
    listError: null,
    formError: null,
    browseError: null,
    selectedError: null,
    downError: null,
    successMessage: null,
    compSuccessMessage: null
}

export default function(state = initialState, action) {
    // If this is one of our reponses
    if(action.payload !== undefined){
        // If this is one of our reponses
        if(action.payload.success !== undefined){
            // If the response was a success
            if(action.payload.success === true){
                // Every Dispatch is sent to every Reducer, only act on the Actions that concern this Reducer
                switch(action.type){
                    case FETCH_GOALS:
                        return {...state, goalList: JSON.parse(action.payload.payload), listError: null};
                    case BROWSE_GOALS:
                        return {...state, goalList: JSON.parse(action.payload.payload), browseError: null};
                    case FETCH_GOAL:
                        return {...state, selectedGoal: JSON.parse(action.payload.payload), selectedError: null};
                    case NEW_GOAL:
                        return {...state, formError: null, successMessage: action.payload.message};
                    case COMPLETE_GOAL:
                        return {...state, selectedGoal: JSON.parse(action.payload.payload), downError: null, 
                            compSuccessMessage: action.payload.message};
                    case DOWN_GOAL:
                        return {...state, selectedGoal: JSON.parse(action.payload.payload), downError: null};
                    // case CLEAR_MESSAGES: Reset all Error/Success messages but retain all other state
                    case CLEAR_MESSAGES:
                        return {...state, listError: null, formError: null, browseError: null, 
                            selectedError: null, downError: null, successMessage: null, compSuccessMessage: null};
                    default:
                        return state;
                }
            }
            // Else handle the failed response
            else{
                // Every Dispatch is sent to every Reducer, only act on the Actions that concern this Reducer
                switch(action.type){
                    case FETCH_GOALS:
                        return {...state, goalList: [], listError: action.payload.message};
                    case BROWSE_GOALS:
                        return {...state, goalList: [], browseError: action.payload.message};
                    case NEW_GOAL:
                        return {...state, goalList: [], formError: action.payload.message, successMessage: null};
                    case COMPLETE_GOAL:
                        return {...state, downError: action.payload.message, compSuccessMessage: null};
                    case FETCH_GOAL:
                        return {...state, selectedError: action.payload.message};
                    case DOWN_GOAL:
                        return {...state, downError: action.payload.message}
                    default:
                        return state;
                }
            }
        }
    }
    // The library first calls this function with a null action to retrieve the initial state
    return initialState;
}