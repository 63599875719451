import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';

import { LOGIN_PAGE, BROWSE_PAGE, GOAL_DETAILS_PAGE, 
  REWARDS_PAGE, TOKENS_PAGE, TOKEN_DETAILS_PAGE,
  REWARD_DETAILS_PAGE, GOALS_PAGE, WELCOME_PAGE, 
  CREATE_USER_PAGE, CONTACT_PAGE, ABOUT_PAGE, CHANGES_PAGE,
  ACCOUNT_PAGE, UNKNOWN_PAGE, SUB_NONE, SUB_CREATE_GOAL, 
  SUB_COMP_REQ, SUB_MY_GOALS, SUB_GOALS, SUB_COMPLETIONS,
  SUB_CREATE_REWARD, SUB_REMP_REQ, SUB_MY_REWARDS, 
  SUB_OUT_TOKENS, SUB_MY_TOKENS, SUB_MY_REQS, SUB_REWARDS
} from '../../pages.js';

import Browsepage from './Browsepage';
import Goaldetailspage from './Goaldetailspage';
import Goalspage from './Goalspage';
import Loginpage from './Loginpage';
import Createuserpage from './Createuserpage';
import Rewardspage from './Rewardspage';
import Tokenspage from './Tokenspage';
import Welcome from './Welcome';
import Rewarddetailspage from './Rewarddetailspage';
import Tokendetailspage from './Tokendetailspage';
import Contactpage from './Contactpage';
import Aboutpage from './Aboutpage';
import Changespage from './Changespage';
import Accountpage from './Accountpage';
import Unknown from './Unknown';

import Userbar from '../Userbar';
import Footer from '../Footer';


class Layout extends Component {
    onClickWelcome() {
        this.props.history.push('/');
    }

    render() {
        let renderPage = this.props.match.params.filter;
        let subPage = this.props.match.params.subFilter;
        let renderElement;

        // if the attempted URL is longer than expected
        if(this.props.match.params.unusedFilter !== undefined) {
            renderPage = UNKNOWN_PAGE;
        }

        switch(renderPage){
            case LOGIN_PAGE:
                renderElement = <Loginpage history={this.props.history}/>;
                break;
            case CREATE_USER_PAGE:
                renderElement = <Createuserpage history={this.props.history}/>;
                break;
            case BROWSE_PAGE:
                // Possible subPages: SUB_NONE, SUB_GOALS, SUB_REWARDS
                // If the subFilter matches a Possible subPage, render the expected component
                if(subPage === undefined || subPage === SUB_NONE 
                    || subPage === SUB_GOALS || subPage === SUB_REWARDS) {
                        renderElement = <Browsepage history={this.props.history} subPage={subPage}/>;
                }
                else {
                    renderElement = <Unknown />;
                }
                break;
            case GOALS_PAGE:
                // Possible subPages: SUB_NONE, SUB_CREATE_GOAL, SUB_COMP_REQ, SUB_MY_GOALS, SUB_COMPLETIONS
                // If the subFilter matches a Possible subPage, render the expected component
                if(subPage === undefined || subPage === SUB_NONE 
                    || subPage === SUB_CREATE_GOAL || subPage === SUB_COMP_REQ 
                    || subPage === SUB_MY_GOALS || subPage === SUB_COMPLETIONS) {
                        renderElement = <Goalspage history={this.props.history} subPage={subPage}/>;
                }
                else {
                    renderElement = <Unknown />;
                }
                break;
            case GOAL_DETAILS_PAGE:
                renderElement = <Goaldetailspage history={this.props.history}/>;
                break;
            case REWARDS_PAGE:
                // Possible subPages: SUB_NONE, SUB_CREATE_REWARD, SUB_REMP_REQ, SUB_MY_REWARDS, SUB_MY_REQS
                // If the subFilter matches a Possible subPage, render the expected component
                if(subPage === undefined || subPage === SUB_NONE 
                    || subPage === SUB_CREATE_REWARD || subPage === SUB_REMP_REQ 
                    || subPage === SUB_MY_REWARDS || subPage === SUB_MY_REQS) {
                        renderElement = <Rewardspage history={this.props.history} subPage={subPage}/>;
                }
                else {
                    renderElement = <Unknown />;
                }
                break;
            case REWARD_DETAILS_PAGE:
                renderElement = <Rewarddetailspage />;
                break;
            case TOKENS_PAGE:
                // Possible subPages: SUB_NONE, SUB_MY_TOKENS, SUB_OUT_TOKENS
                // If the subFilter matches a Possible subPage, render the expected component
                if(subPage === undefined || subPage === SUB_NONE 
                    || subPage === SUB_MY_TOKENS || subPage === SUB_OUT_TOKENS) {
                        renderElement = <Tokenspage history={this.props.history} subPage={subPage}/>;
                }
                else {
                    renderElement = <Unknown />;
                }
                break;
            case TOKEN_DETAILS_PAGE:
                renderElement = <Tokendetailspage history={this.props.history}/>;
                break;
            case CONTACT_PAGE:
                renderElement = <Contactpage />;
                break;
            case ABOUT_PAGE:
                renderElement = <Aboutpage />;
                break;
            case CHANGES_PAGE:
                renderElement = <Changespage />;
                break;
            case ACCOUNT_PAGE:
                renderElement = <Accountpage />;
                break;
            case WELCOME_PAGE:
            case undefined:
                renderElement = <Welcome history={this.props.history}/>;
                break;
            case UNKNOWN_PAGE:
            default:
                renderElement = <Unknown />;
                break;
        }
        
        return (
            <div className="App">
                <div className="TopContentArea TopSpacer">
                    <div className="AppTitleContainer">
                        <div className="TitleBox Pointer" onClick={() => this.onClickWelcome()}>
                            <label className="Pointer AppTitle"  onClick={() => this.onClickWelcome()}>Goal Get 'Em</label>
                        </div>
                    </div>
                    <Userbar history={this.props.history}/>
                </div>
                <div className="TopSpacer"></div>
                <div className="BodyContainer">
                    {renderElement}
                </div>
                <Footer />
            </div>
        );   
    }
}

Layout.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(Layout);