import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';
import '../../css/Changespage.css';

import { clearMessages } from '../../actions/userActions';

class Changespage extends Component {
    componentWillMount() {
        // Clear any previous Error/Success messages on page load
        this.props.clearMessages();
    }
    
    componentDidMount() {
        // Scroll to top of the page
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <div>
                <div className="White ContentArea">
                    <div className="ContentPadder">
                        <h1 className="SlimHeader">What's New?</h1>
                        <h2 className="SlimHeader">
                            Alpha 0.0.5<span className="HeaderSubtext"> - Released on 08/17/2019</span>
                        </h2>
                        <ul className="ChangelogList">
                            <li>Moved the Rescind Tokens button</li>
                            <li>Added automatic redirect to login page after current session expires</li>
                            <li>Added ability to use browser Refresh without being logged out</li>
                            <li>Added ability to type in page URL without being logged out</li>
                            <li>Added Error/Success messages where appropriate</li>
                            <li>Changed top navigation order from Browse|Goals|Rewards|Tokens</li>
                        </ul>
                    </div>
                </div>
                <div className="Purple ContentArea">
                    <div className="ContentPadder">
                        <h1 className="SlimHeader">Previous Version</h1>
                        <h2 className="SlimHeader">
                            Alpha 0.0.4<span className="HeaderSubtext"> - Released on 08/04/2019</span>
                        </h2>
                        <ul className="ChangelogList">
                            <li>Fixed double entry in Browser history when clicking gear menu items</li>
                            <li>Navigation and page content rearranged</li>
                            <li>Added the ability to change Password on the Account page</li>
                            <li>Added a basic Subscribe button to Account page</li>
                            <li>Added Browse by Rewards</li>
                        </ul>                
                    </div>
                </div>
                <div className="White ContentArea">
                    <div className="ContentPadder">
                        <h1 className="SlimHeader">Previous Version</h1>
                        <h2 className="SlimHeader">
                            Alpha 0.0.3<span className="HeaderSubtext"> - Released on 08/03/2019</span>
                        </h2>
                        <ul className="ChangelogList">
                            <li>Navigate using the Browser Back and Forward buttons now possible</li>
                            <li>Navigation using the sub-page links while on same page fixed</li>
                        </ul>
                    </div>
                </div>
                <div className="Purple ContentArea">
                    <div className="ContentPadder">
                        <h1 className="SlimHeader">Previous Version</h1>
                        <h2 className="SlimHeader">
                            Alpha 0.0.2<span className="HeaderSubtext"> - Released on 07/23/2019</span>
                        </h2>
                        <ul className="ChangelogList">
                            <li>Fixed the SSL integration</li>
                        </ul>                  
                    </div>
                </div>
                <div className="White ContentArea">
                    <div className="ContentPadder">
                        <h1 className="SlimHeader">Previous Version</h1>
                        <h2 className="SlimHeader">
                            Alpha 0.0.1<span className="HeaderSubtext"> - Released on 07/18/2019</span>
                        </h2>
                        <ul className="ChangelogList">
                            <li>"What's New?" page added to footer</li>
                            <li>First Version number given</li>
                            <li>Account Details page added</li>
                            <li>Subscription Tiers added</li>
                            <li>Under Construction added to Welcome</li>
                            <li>SSL Cert and automatic renewal</li>
                        </ul> 
                    </div>
                </div>
                <div className="Purple ContentArea">
                    <div className="ContentPadder">
                        <h1 className="SlimHeader">Previous Version</h1>
                        <h2 className="SlimHeader">
                            Pre Alpha<span className="HeaderSubtext"> - Released circa 07/01/2019</span>
                        </h2>
                        <p>
                            This marks the very first time this app can be reached publicly on the internet! You have to know the Droplet IP and Port though...tricky.
                        </p>                     
                    </div>
                </div>
            </div>
        );
    }
}

Changespage.propTypes = {
    clearMessages: PropTypes.func.isRequired
}

export default connect(null, { clearMessages })(Changespage);