import { FETCH_REDEMPTION_REQUESTS, RESOLVE_REDEMPTION, FETCH_SENT_REDEMPTIONS } from './types';
import { PROXY_URL, API_PATH } from '../config';
import { handleResponse } from './helpers/handleResponse';
import { handleError } from './helpers/handleError';
import { createAuthHeader } from './helpers/createHeader';

import { fetchOwnRewards } from './rewardActions';

export function fetchRedemptionRequests(user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/RedemptionRequests', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(user)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: FETCH_REDEMPTION_REQUESTS,
                payload: json
            })
        }).catch((error) => handleError(error, dispatch, FETCH_REDEMPTION_REQUESTS));
    }
}

export function resolveRedemption(redemption, user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/ResolveRedemption', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(redemption)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: RESOLVE_REDEMPTION,
                payload: json
            })
            //Also dispatch the fetchRedemptionRequests and fetchOwnRewards method so our lists refresh
            dispatch(fetchRedemptionRequests(user));
            dispatch(fetchOwnRewards(user));
        }).catch((error) => handleError(error, dispatch, RESOLVE_REDEMPTION));
    }
}

export function fetchSentRedemptions(user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/SentRedemptions', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(user)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: FETCH_SENT_REDEMPTIONS,
                payload: json
            })
        }).catch((error) => handleError(error, dispatch, FETCH_SENT_REDEMPTIONS));
    }
}