export const LOGIN_PAGE = 'login';
export const CREATE_USER_PAGE = 'new_user';
export const BROWSE_PAGE = 'browse';
export const GOAL_DETAILS_PAGE = 'goal_details';
export const REWARDS_PAGE = 'rewards';
export const REWARD_DETAILS_PAGE = 'reward_details';
export const TOKENS_PAGE = 'tokens';
export const TOKEN_DETAILS_PAGE = 'token_details';
export const GOALS_PAGE = 'goals';
export const WELCOME_PAGE = 'welcome';
export const CONTACT_PAGE = 'contact';
export const ABOUT_PAGE = 'about';
export const CHANGES_PAGE = 'whats_new';
export const ACCOUNT_PAGE = 'account';
export const UNKNOWN_PAGE = 'unknown';

export const SUB_NONE = 'main';
export const SUB_GOALS = 'goals';
export const SUB_REWARDS = 'rewards';
export const SUB_COMPLETIONS = 'completions';
export const SUB_CREATE_GOAL = 'create_goal';
export const SUB_COMP_REQ = 'comp_requests';
export const SUB_MY_GOALS = 'my_goals';
export const SUB_CREATE_REWARD = 'create_reward';
export const SUB_REMP_REQ = 'redeem_requests';
export const SUB_MY_REWARDS = 'my_rewards';
export const SUB_OUT_TOKENS = 'out_tokens';
export const SUB_MY_TOKENS = 'my_tokens';
export const SUB_MY_REQS = 'my_requests';