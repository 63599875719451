import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';
import '../../css/Loginform.css';

import { verifyUser } from '../../actions/userActions';

class Loginform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();

        const user = {
            username: this.state.username,
            password: this.state.password
        };
        
        this.props.verifyUser(user);

        //Clearing the form fields
        this.setState({
            username: '',
            password: ''
        });
    }

    render() {
        let renderError;
        if(this.props.error !== null){
            renderError = <div className="ErrorMessage"><label>{this.props.error}</label></div>;
        }

        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    {renderError}
                    <div className="CreateFormUserArea">
                        <label className="FormLabel">Username</label><br/>
                        <input type="text" name="username" className="PurpleBorder" onChange={this.onChange} value={this.state.username} />
                    </div>
                    <div className="CreateFormPwArea">
                        <label className="FormLabel">Password</label><br/>
                        <input type="password" name="password" className="PurpleBorder" onChange={this.onChange} value={this.state.password} />
                    </div>
                    <button className="Pointer UserFormSubmitButton LoginFormButton" type="submit">Login</button>
                </form>
            </div>
        ); 
    }
}

Loginform.propTypes = {
    verifyUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    error: state.user.verifyError
});

export default connect(mapStateToProps, { verifyUser })(Loginform);