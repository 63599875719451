import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';
import '../../css/Hzontal.css';

import Completionoptions from '../Completionoptions';

class Completionrequests extends Component {
    constructor(props) {
        super(props);
        // Create a refrence to the div component that needs to be scrolled horizontally
        this.horizontalRef = React.createRef();
        this.onClickScroll = this.onClickScroll.bind(this);
    }

    onClickScroll(amount) {
        this.horizontalRef.current.scrollLeft += amount;
    }

    render() {
        let displayError = {"display": "none"};
        if(this.props.error !== null){
            displayError = {"display": "inline-block"};
        }

        // Scroll button stuff
        let sizeOfHzontalItem = 720;
        let leftArrow = "<";
        let rightArrow = ">";
        let displayScrollButtons = {"display": "inline-block"};
        if(this.props.completionList.length <= 1) {
            displayScrollButtons = {"display": "none"};
        }

        let completionItems = this.props.completionList.map(completion => 
            <div className="HorizontalListItem" key={completion.id}>
                <div className="HzontalLabelArea HzontalTopItem">
                    <label>Accomplished</label>
                </div>
                <div className="HzontalTitleArea">
                    <label>{completion.goalTitle}</label>
                </div>
                <div className="HzontalLabelArea">
                    <label className="PurpleLabel">Message</label>
                </div>
                <div className="HzontalMessageArea">
                    <label>{completion.message}</label>
                </div>
                <div className="HzontalLabelArea">
                    <label className="PurpleLabel">Created | Completed</label>
                </div>
                <div className="HzontalUserArea">
                    <label>{completion.setterUsername} | {completion.performerUsername}</label>
                </div>
                <Completionoptions completion={completion} />
            </div>
        );

        return (
            <div className="HzontalPanelArea">
                <div className="HzontalScrollArea">
                    <button className="Pointer HzontalScrollButton" style={displayScrollButtons} onClick={() => this.onClickScroll(-sizeOfHzontalItem)}>{leftArrow}</button>
                </div>
                <div className="HorizontalList" ref={this.horizontalRef}>                    
                    <div className="HorizontalListItem ErrorMessage HzontalTopItem" style={displayError}>
                        <label>{this.props.error}</label>
                    </div>
                    {completionItems}
                </div>
                <div className="HzontalScrollArea" style={displayScrollButtons}>
                    <button className="Pointer HzontalScrollButton" onClick={() => this.onClickScroll(sizeOfHzontalItem)}>{rightArrow}</button>
                </div>
            </div>
        );
    }
}

Completionrequests.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    user: state.user,
});

export default connect(mapStateToProps)(Completionrequests);