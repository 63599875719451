import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { completeGoal, takeDownGoal } from '../actions/goalActions';

class Goalcompleter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ''
        }

        this.onChange = this.onChange.bind(this);
        this.onClickComplete = this.onClickComplete.bind(this);
        this.onClickTakeDown = this.onClickTakeDown.bind(this);
    }

    componentDidUpdate(prevProps) {
        // If the User succceeds in Completing a Goal
        if (this.props.success !== prevProps.success && this.props.success != null) {
            // Empty the message box
            this.setState({message: ''});
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onClickComplete(goal, user) {
        //Add message content to goal for Completion
        goal['completeMessage'] = this.state.message;

        this.props.completeGoal(goal, user);
    }

    onClickTakeDown(goal, user) {
        //Add message content to goal for Completion
        goal['completeMessage'] = this.state.message;
        
        this.props.takeDownGoal(goal, user);
    }

    render() {
        let renderError;
        if(this.props.error !== null){
            renderError = <div className="ErrorMessage CompleterErrorMessage"><label>{this.props.error}</label></div>;
        }

        let renderSuccess;
        if(this.props.success !== null){
            renderSuccess = <div className="SuccessMessage"><label>{this.props.success}</label></div>;
        }

        //build custom goal and user objects to distinguish the .id properties
        let copyGoal;
        if(this.props.selectedGoal !== null){
            copyGoal = this.props.selectedGoal;
        }
        else {
            copyGoal = {
                id: 0,
                ownerId: 0,
                complete: true,
                limitCount: null,
                count: 0
            };
        }
        let goal = {
            goalId: copyGoal.id,
            complete: copyGoal.complete,
            limitCount: copyGoal.limitCount,
            count: copyGoal.count,
            ownerId: copyGoal.ownerId
        };
        let user = {
            userId: this.props.user.id
        };

        let displayComplete = {"display": "none"};
        let displayTakeDown = {"display": "none"};
        if(this.props.user.id !== goal.ownerId){
            if(goal.limitCount !== null) {
                if(goal.count < goal.limitCount){
                    displayComplete = {"display": "block"};
                }
            }
            else{
                displayComplete = {"display": "block"};
            }
        }
        else {
            if(goal.complete === false) {
                displayTakeDown = {"display": "block"};
            }
        }

        return (
            <div>
                <div style={displayComplete}>
                    <div className="DetailsLabel">
                        <label>Include Message</label>
                    </div>
                    <div className="DetailsDescription">
                        <textarea className="PurpleBorder FullWidth" name="message" onChange={this.onChange} value={this.state.message} />
                    </div>
                    {renderError}{renderSuccess}
                    <button className="Pointer DetailsSubmitButton" onClick={() => this.onClickComplete(goal, user)}>Complete</button>
                </div>
                <div style={displayTakeDown}>
                    <div className="DetailsLabel">
                        <label>Include Message</label>
                    </div>
                    <div className="DetailsDescription">
                        <textarea className="PurpleBorder FullWidth" name="message" onChange={this.onChange} value={this.state.message} />
                    </div>
                    {renderError}
                    <button className="Pointer DetailsSubmitButton" onClick={() => this.onClickTakeDown(goal, user)}>Take Down</button>
                </div>
            </div>
        )        
    }
}

Goalcompleter.propTypes = {
    completeGoal: PropTypes.func.isRequired,
    takeDownGoal: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    selectedGoal: state.goals.selectedGoal,
    user: state.user,
    error: state.goals.downError,
    success: state.goals.compSuccessMessage
});

export default connect(mapStateToProps, { completeGoal, takeDownGoal })(Goalcompleter);