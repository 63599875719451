import { SETTINGS_TOKEN, GOAL_GETEM_TOKEN, GOAL_GETEM_USERNAME, 
    GOAL_GETEM_PASSWORD, GOAL_GETEM_NEW_PASSWORD } from '../../token';

export function createPlainHeader() {
    let h = new Headers();
    h.append('Content-Type', 'application/json');
    
    return h;
}

export function createAuthHeader() {
    let h = createPlainHeader();
    h.append(GOAL_GETEM_TOKEN, localStorage.getItem(SETTINGS_TOKEN));
    
    return h;
}

export function createVerificationHeader(user) {
    let h = createPlainHeader();
    h.append('Access-Control-Allow-Headers', GOAL_GETEM_USERNAME + ", " + GOAL_GETEM_PASSWORD + ", " + GOAL_GETEM_NEW_PASSWORD);
    h.append(GOAL_GETEM_USERNAME, user.username);
    h.append(GOAL_GETEM_PASSWORD, user.password);
    h.append(GOAL_GETEM_NEW_PASSWORD, user.newPassword);
    
    return h;
}