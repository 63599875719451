import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';
import '../../css/Hzontal.css';

import { fetchRewardDetails } from '../../actions/rewardActions';

import { REWARD_DETAILS_PAGE } from '../../pages';

class Rewards extends Component {
    constructor(props) {
        super(props);

        this.onClickReward = this.onClickReward.bind(this);

        // Create a refrence to the div component that needs to be scrolled horizontally
        this.horizontalRef = React.createRef();
        this.onClickScroll = this.onClickScroll.bind(this);
    }

    onClickReward(reward) {
        this.props.fetchRewardDetails(reward);
        
        this.props.history.push('/' + REWARD_DETAILS_PAGE);
    }

    onClickScroll(amount) {
        this.horizontalRef.current.scrollLeft += amount;
    }

    render() {
        let displayError = {"display": "none"};
        if(this.props.error !== null){
            displayError = {"display": "inline-block"};
        }

        // Scroll button stuff
        let sizeOfHzontalItem = 720;
        let leftArrow = "<";
        let rightArrow = ">";
        let displayScrollButtons = {"display": "inline-block"};
        if(this.props.rewardList.length <= 1) {
            displayScrollButtons = {"display": "none"};
        }

        let rewardItems = this.props.rewardList.map(reward => {
            let available = 'Unlimited';
            if(reward.limitCount !== null){
                available = reward.limitCount - (reward.count + reward.pendingCount);
            };
            if(reward.complete){
                available = 'None';
            }

            let displayOwner = {"display": "block"};
            if(reward.ownerId === this.props.user.id){
                displayOwner = {"display": "none"};
            }

            return (
                <div className="HorizontalListItem Pointer" key={reward.id} onClick={() => this.onClickReward(reward)}>
                    <div className="HzontalTitleArea HzontalTopItem Pointer">
                        <label className="RewardTitle Pointer">{reward.title}</label>
                    </div>
                    <div className="HzontalMessageArea Pointer">
                        <label className="Pointer">{reward.description}</label>
                    </div>
                    <div className="HzontalLabelArea Pointer">
                        <label className="Pointer">Tier</label>
                    </div>
                    <div className="HzontalTierArea Pointer">
                        <label className="Pointer">{reward.tierName.charAt(0).toUpperCase() + reward.tierName.slice(1)}</label>
                    </div>
                    <div className="HzontalLabelArea Pointer" style={displayOwner}>
                        <label className="Pointer">From User</label>
                    </div>
                    <div className="HzontalUserArea Pointer" style={displayOwner}>
                        <label className="Pointer">{reward.ownerUsername}</label>
                    </div>
                    <div className="HzontalLabelArea Pointer">
                        <label className="Pointer">Quantity Available</label>
                    </div>
                    <div className="HzontalQuantityArea Pointer">
                        <label className="Pointer">{available} ({reward.pendingCount} Pending)</label>
                    </div>
                </div>
            );
        }); 

        return (
            <div className="HzontalPanelArea">
                <div className="HzontalScrollArea">
                    <button className="Pointer HzontalScrollButton" style={displayScrollButtons} onClick={() => this.onClickScroll(-sizeOfHzontalItem)}>{leftArrow}</button>
                </div>
                <div className="HorizontalList" ref={this.horizontalRef}>                    
                    <div className="HorizontalListItem ErrorMessage HzontalTopItem" style={displayError}>
                        <label>{this.props.error}</label>
                    </div>
                    {rewardItems}
                </div>
                <div className="HzontalScrollArea" style={displayScrollButtons}>
                    <button className="Pointer HzontalScrollButton" onClick={() => this.onClickScroll(sizeOfHzontalItem)}>{rightArrow}</button>
                </div>
            </div>
        );
    }
}

Rewards.propTypes = {
    fetchRewardDetails: PropTypes.func.isRequired,
    rewardList: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    error: PropTypes.string
};

const mapStateToProps = state => ({
    rewardList: state.rewards.rewardList,
    user: state.user,
    error: state.rewards.listError
});

export default connect(mapStateToProps, { fetchRewardDetails })(Rewards);