import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import '../css/Layout.css';
import '../css/Userbadge.css';

import { ACCOUNT_PAGE } from '../pages';

import { logOut } from '../actions/userActions';

class Userbadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDropdown: false
        }

        this.onClickOpenDropdown = this.onClickOpenDropdown.bind(this);
        this.onMouseLeaveCloseDropdown = this.onMouseLeaveCloseDropdown.bind(this);

        this.onClickLogout = this.onClickLogout.bind(this);
    }

    // This primarily opens the dropdown.
    // Technically is toggle, so allows open/close if clicked twice.
    onClickOpenDropdown() {
        this.setState({showDropdown: !this.state.showDropdown});
    }

    // Closes the dropdown if the cursor leaves the area
    onMouseLeaveCloseDropdown() {
        this.setState({showDropdown: false});
    }

    onClickLogout() {
        this.props.logOut();
    }

    render() {
        let user = this.props.user;

        let displayDropdown = this.state.showDropdown ? {display: 'block'} : {display: 'none'};

        return (
            <div className="Userbadge">
                <div className="BadgePadder">
                    <label>Hi, </label>
                    <label>{user.username}!</label>
                    <label className="Pointer UserOptionsToggle" onClick={() => this.onClickOpenDropdown()}>v</label>
                    <div className="UserDropdown" style={displayDropdown} onMouseLeave={this.onMouseLeaveCloseDropdown}>
                        <div className="UserDropPadder">
                            <NavLink className="Pointer UserDropItem" to={'/' + ACCOUNT_PAGE}>Account</NavLink><br />
                            <label className="UserDropItem Pointer" onClick={() => this.onClickLogout()}>Logout</label>
                        </div>                        
                    </div>
                </div>
            </div>
        );   
    }
}

Userbadge.propTypes = {
    user: PropTypes.object.isRequired,
    logOut: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, { logOut })(Userbadge);