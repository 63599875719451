import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';
import '../../css/Rewardspage.css';

import { SUB_NONE, SUB_CREATE_REWARD,
    SUB_REMP_REQ, SUB_MY_REWARDS, SUB_MY_REQS } from '../../pages';
import Newrewardform from '../forms/Newrewardform';
import Redemptionrequests from '../hzontals/Redemptionrequests';
import Rewards from '../hzontals/Rewards.js';
import { fetchRedemptionRequests, fetchSentRedemptions } from '../../actions/redemptionActions';
import { fetchOwnRewards } from '../../actions/rewardActions';
import { clearMessages } from '../../actions/userActions';

class Rewardspage extends Component {
    constructor(props) {
        super(props);

        this.newRef = React.createRef();
        this.redempRef = React.createRef();
        this.rewardsRef = React.createRef();
        this.claimedRef = React.createRef();
        // Prevents render from moving page except by navigation
        this.previousSubPage = '';
    }

    componentWillMount() {
        // Clear any previous Error/Success messages on page load
        this.props.clearMessages();

        this.props.fetchRedemptionRequests(this.props.user);
        this.props.fetchOwnRewards(this.props.user);
        this.props.fetchSentRedemptions(this.props.user);
    }

    componentDidMount() {
        this.moveWindowPosition();
    }

    moveWindowPosition() {
        // If refs have been placed on page
        if(this.newRef.current !== null){
            let topOffset = 0;
            switch(this.props.subPage){
                case SUB_CREATE_REWARD:
                    topOffset = this.newRef.current.offsetTop;
                    break;
                case SUB_REMP_REQ:
                    topOffset = this.redempRef.current.offsetTop;
                    break;
                case SUB_MY_REWARDS:
                    topOffset = this.rewardsRef.current.offsetTop;
                    break;
                case SUB_MY_REQS:
                    topOffset = this.claimedRef.current.offsetTop;
                    break;
                case SUB_NONE:
                default:
                    break;
            }
            window.scrollTo(0, topOffset);
        }
    }

    render() {
        // Prevents re-render from moving page
        if(this.previousSubPage !== this.props.subPage){
            this.moveWindowPosition();
            this.previousSubPage = this.props.subPage;
        }

        return (
            <div>
                <div className="White ContentArea" ref={this.claimedRef}>
                    <div className="ContentPadder">
                        <div className="LeftRightSmall MyRedemRequestsArea">
                            <h1 className="SlimHeader">Claimed Rewards</h1>
                            <p>
                                Proin neque mauris, faucibus et nisi eget, facilisis varius enim. Maecenas at elementum dolor. Etiam placerat maximus leo, vitae mattis enim convallis at. Nullam eleifend ligula urna, luctus pharetra est fermentum sit amet. Suspendisse urna urna, semper eget faucibus id, suscipit varius velit. Aenean nec lorem et est tempus tincidunt vel non odio. Ut gravida condimentum justo eu sagittis. Donec vehicula suscipit libero, non ultrices nisi. Sed tincidunt turpis at tortor euismod maximus.
                            </p>
                        </div>
                        <div className="LeftRightBig">
                            <Redemptionrequests redemptionList={this.props.sentList} error={this.props.sentError}/>
                        </div>
                    </div>
                </div>
                <div className="Purple ContentArea" ref={this.redempRef}>
                    <div className="ContentPadder">
                        <div className="LeftRightBig">
                            <Redemptionrequests redemptionList={this.props.receivedList} error={this.props.receivedError}/>
                        </div>
                        <div className="LeftRightSmall RewardRedemArea">
                            <h1 className="SlimHeader">Redemption Requests</h1> 
                            <p>
                                Proin neque mauris, faucibus et nisi eget, facilisis varius enim. Maecenas at elementum dolor. Etiam placerat maximus leo, vitae mattis enim convallis at. Nullam eleifend ligula urna, luctus pharetra est fermentum sit amet. Suspendisse urna urna, semper eget faucibus id, suscipit varius velit. Aenean nec lorem et est tempus tincidunt vel non odio. Ut gravida condimentum justo eu sagittis. Donec vehicula suscipit libero, non ultrices nisi. Sed tincidunt turpis at tortor euismod maximus.
                            </p>    
                        </div>
                    </div>
                </div>
                <div className="White ContentArea" ref={this.rewardsRef}>
                    <div className="ContentPadder">
                        <div className="LeftRightSmall RewardClaimedArea">
                            <h1 className="SlimHeader">My Rewards</h1> 
                            <p>
                                Proin neque mauris, faucibus et nisi eget, facilisis varius enim. Maecenas at elementum dolor. Etiam placerat maximus leo, vitae mattis enim convallis at. Nullam eleifend ligula urna, luctus pharetra est fermentum sit amet. Suspendisse urna urna, semper eget faucibus id, suscipit varius velit. Aenean nec lorem et est tempus tincidunt vel non odio. Ut gravida condimentum justo eu sagittis. Donec vehicula suscipit libero, non ultrices nisi. Sed tincidunt turpis at tortor euismod maximus.
                            </p>    
                        </div>
                        <div className="LeftRightBig">
                            <Rewards history={this.props.history}/>
                        </div>
                    </div>
                </div>
                <div className="Purple ContentArea" ref={this.newRef}>
                    <div className="ContentPadder">
                        <div className="LeftRightBig">
                            <Newrewardform />
                        </div>
                        <div className="LeftRightSmall NewGoalTalkArea">
                            <h1 className="SlimHeader">Create New Reward</h1>
                            <p>
                                This is where you create new Rewards to incentivize other Users. Maecenas at elementum dolor. Etiam placerat maximus leo, vitae mattis enim convallis at. Nullam eleifend ligula urna, luctus pharetra est fermentum sit amet. Suspendisse urna urna, semper eget faucibus id, suscipit varius velit. Aenean nec lorem et est tempus tincidunt vel non odio.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );   
    }
}

Rewardspage.propTypes = {
    fetchRedemptionRequests: PropTypes.func.isRequired,
    fetchSentRedemptions: PropTypes.func.isRequired,
    fetchOwnRewards: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    receivedList: PropTypes.array.isRequired,
    sentList: PropTypes.array.isRequired,
    clearMessages: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    user: state.user,
    receivedList: state.redemptions.receivedList,
    sentList: state.redemptions.sentList,
    receivedError: state.redemptions.receivedError,
    sentError: state.redemptions.sentError
});

export default connect(mapStateToProps, { fetchRedemptionRequests, 
    fetchSentRedemptions, fetchOwnRewards, clearMessages })(Rewardspage);