import { FETCH_OWN_REWARDS, CREATE_REWARD, FETCH_ELIGIBLE_REWARDS, 
    REWARD_DETAILS, FETCH_GOAL_REWARDS, TAKE_DOWN_REWARD, BROWSE_REWARDS } from './types';
import { PROXY_URL, API_PATH } from '../config';
import { handleResponse } from './helpers/handleResponse';
import { handleError } from './helpers/handleError';
import { createAuthHeader } from './helpers/createHeader';

export function fetchOwnRewards(user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/OwnRewards', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(user)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: FETCH_OWN_REWARDS,
                payload: json
            });
        }).catch((error) => handleError(error, dispatch, FETCH_OWN_REWARDS));
    }
}

export function createReward(reward, owner) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/CreateReward', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(reward)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: CREATE_REWARD,
                payload: json
            });
            //Also dispatch the ownRewards method so our list refreshes
            dispatch(fetchOwnRewards(owner));
        }).catch((error) => handleError(error, dispatch, CREATE_REWARD));
    }
}

export function takeDownReward(reward) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/TakeDownReward', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(reward)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: TAKE_DOWN_REWARD,
                payload: json
            });
        }).catch((error) => handleError(error, dispatch, TAKE_DOWN_REWARD));
    }
}

export function fetchTokenRewards(token) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/EligibleRewards', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(token)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: FETCH_ELIGIBLE_REWARDS,
                payload: json
            });
        }).catch((error) => handleError(error, dispatch, FETCH_ELIGIBLE_REWARDS));
    }
}

export function fetchRewardDetails(reward) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/RewardDetails', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(reward)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: REWARD_DETAILS,
                payload: json
            });
        }).catch((error) => handleError(error, dispatch, REWARD_DETAILS));
    }
}

export function fetchGoalRewards(goal) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/GoalRewards', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify(goal)
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: FETCH_GOAL_REWARDS,
                payload: json
            })
        }).catch((error) => handleError(error, dispatch, FETCH_GOAL_REWARDS));
    }
}

export function browseRewardsByTitle(reward, user) {
    return function(dispatch) {
        fetch(PROXY_URL + API_PATH + '/BrowseRewardsByTitle', {
            method: 'POST',
            headers: createAuthHeader(),
            body: JSON.stringify({...reward, ...user})
        })
        .then(handleResponse)
        .then(json => {
            dispatch({
                type: BROWSE_REWARDS,
                payload: json
            })
        }).catch((error) => handleError(error, dispatch, BROWSE_REWARDS));
    }
}