import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import '../../css/Layout.css';

import Rewardclaimer from '../Rewardclaimer';
import { fetchRewardDetails } from '../../actions/rewardActions';
import { clearMessages } from '../../actions/userActions';

class Rewarddetailspage extends Component {
    componentWillMount() {
        // Clear any previous Error/Success messages on page load
        this.props.clearMessages();
    }

    componentDidMount() {
        // Scroll to top of the page
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        // If the User succceeds in Claiming a Reward
        if (this.props.token !== null && this.props.token.count !== prevProps.token.count) {
            // Update the selected Reward details
            this.props.fetchRewardDetails(this.props.selectedReward);
        }
    }
    
    render() {
        let renderError;
        if(this.props.error !== null){
            renderError = <div className="ErrorMessage CompleterErrorMessage"><label>{this.props.error}</label></div>;
        }

        let reward;
        if(this.props.selectedReward !== null){
            reward = this.props.selectedReward;
        }
        else{
            reward = {
                limitCount: 0,
                description: '',
                complete: false,
                title: '',
                tierName: '',
                pendingCount: 0,
                count: 0
            }
        }

        let available = 'Unlimited';
        if(reward.limitCount !== null){
            available = reward.limitCount - (reward.count + reward.pendingCount);
        };
        if(reward.complete){
            available = 'None';
        }

        return (
            <div className="White ContentArea">
                <div className="ContentPadder">
                    <h1 className="SlimHeader">Details</h1>
                    <div className="DetailsTitle">
                        <label>{reward.title}</label>
                    </div>
                    <div className="DetailsDescription">
                        <label>{reward.description}</label>
                    </div>
                    <div className="DetailsLabel">
                        <label>Tier</label>
                    </div>
                    <div className="DetailsSingleLine">
                        <label>{reward.tierName.charAt(0).toUpperCase() + reward.tierName.slice(1)}</label>
                    </div>
                    <div className="DetailsLabel">
                        <label>Quantity Available</label>
                    </div>
                    <div className="DetailsSingleLine">
                        <label>{available} ({reward.pendingCount} Pending)</label>
                    </div>
                    {renderError}
                    <Rewardclaimer history={this.props.history}/>
                </div>
            </div>
        );
    }
}

Rewarddetailspage.propTypes = {
    selectedReward: PropTypes.object,
    fetchRewardDetails: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    selectedReward: state.rewards.selectedReward,
    token: state.tokens.selectedToken,
    error: state.rewards.selectedError
});

export default connect(mapStateToProps, { fetchRewardDetails, clearMessages })(Rewarddetailspage);